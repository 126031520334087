@import "../variaveis.scss";

.ver-mais-active {
  display: block;
}
.ver-mais-invisible {
  display: none;
}

.text-ver-mais {
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #524d4d;
  font-size: 1.2em;
  margin: 24px 0 0;
  display: block;
  width: 100%;
}

.titulo-cursos {
  color: $corMenuPadrao;
  border-radius: 2px;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 2.4em;
  font-weight: 100;
  width: 100%;
}

.container-curso {
  transition: all 0.65s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  // max-height: 650px;
  overflow: hidden;
  list-style: none;
  padding: 0 2px;
  li {
    .container-sistemas-card {
      width: 100%;
      .card-body {
        box-shadow: $boxShadowPadrao;
        border: solid 1px $corBordaCurso;
        justify-content: space-between;
        background-color: $corBranca;
        transition: box-shadow 0.35s;
        align-items: flex-start;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        height: 120px;
        display: flex;
        padding: 22px;
        .card-body-icon {
          display: flex;
          position: absolute;
          height: 100%;
          top: 0;
          left: -2px;
          align-items: center;
        }
        .card-body-after {
          transition: all 0.35s;
          border-radius: 4px;
          position: absolute;
          display: flex;
          // height: 50%;
          width: 2px;
          height: 50%;
          align-items: center;
          text-align: center;
        }
        &:hover {
          box-shadow: none;
          .card-body-after {
            height: 60%;
          }
        }
        .card-body-descricao {
          width: calc(100% - 60px);
          span {
            word-break: break-word;
            color: $corMenuPadrao;
            font-size: 1.4em;
            display: block;
          }
          .titulo-curso {
            font-size: 1.8em;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .descricao-curso {
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            overflow: hidden;
            height: 60px;
          }
        }
      }
    }
  }
  .cad-semcursos {
    span {
      color: $corMenuPadrao;
      font-size: 1.5em;
    }
  }
}

.container-curso-mais-cursos {
  transition: all 0.65s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  // max-height: 650px;
  overflow: hidden;
  list-style: none;
  padding: 0 2px;
  li {
    .container-sistemas-card {
      width: 100%;
      margin-bottom: 10px;
      .card-body {
        box-shadow: $boxShadowPadrao;
        border: solid 1px $corBordaCurso;
        justify-content: space-between;
        background-color: $corBranca;
        transition: box-shadow 0.35s;
        align-items: flex-start;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        height: 120px;
        display: flex;
        padding: 22px;
        .card-body-icon {
          display: flex;
          position: absolute;
          height: 100%;
          top: 0;
          left: -2px;
          align-items: center;
        }
        .card-body-value {
          position: absolute;
          top: 0;
          right: 0;
          padding: 15px;
          span {
            font-size: 1.6em;
            color: $iconModColor;
          }
        }
        .card-body-after {
          transition: all 0.35s;
          border-radius: 4px;
          position: absolute;
          display: flex;
          // height: 50%;
          width: 2px;
          height: 50%;
          align-items: center;
          text-align: center;
        }
        &:hover {
          box-shadow: none;
          .card-body-after {
            height: 60%;
          }
        }
        .card-body-descricao {
          width: calc(100% - 60px);
          span {
            word-break: break-word;
            color: $corMenuPadrao;
            font-size: 1.4em;
            display: block;
          }
          .titulo-curso {
            font-size: 1.8em;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .descricao-curso {
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            overflow: hidden;
            height: 60px;
          }
        }
      }
    }
  }
}
.card-mais-cursos {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 30px 0px;
}
.container-curso-mais-cursos {
  width: 100% !important;
}

.container-curso li {
  margin-bottom: 10px;
}

@media (min-width: 731px) {
  .ver-mais-active {
    display: none;
  }
  .ver-mais-invisible {
    display: none;
  }

  .titulo-cursos {
    width: 230px;
  }
  .container-curso {
    justify-content: space-between;
    max-height: initial;
    overflow: initial;
    flex-wrap: wrap;
    display: flex;
    padding: 0;
  }
  .container-curso li {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: calc(50% - 7.5px);
  }
  .container-curso li:not(:first-child) {
    margin-top: 0;
  }
  .container-curso li:nth-child(n + 3) {
    margin-top: 15px;
  }
}

@media (min-width: 960px) {
  .container-curso {
    justify-content: flex-start;
    li {
      .container-sistemas-card {
        .card-body {
          padding: 16px;
        }
      }
    }
  }
  .container-curso li {
    width: calc((100% - 40px) / 3);
    display: flex;
  }
  .container-curso li:nth-child(n + 3) {
    margin-top: 0;
  }
  .container-curso li:nth-child(n + 4) {
    margin-top: 20px;
  }
  .container-curso li:not(:nth-child(3n)) {
    margin-right: 20px;
  }
}

@media (max-width: 700px) {
  .card-body-value {
    left: 0;
    bottom: 0;
    top: revert !important;
    right: revert !important;
    span {
      font-size: 1.4em !important;
    }
  }
}

@media (min-width: 1200px) {
  .container-curso li {
    width: calc((100% - 60px) / 4);
  }
  .container-curso li:nth-child(n + 4) {
    margin-top: 0;
  }
  .container-curso li:nth-child(n + 5) {
    margin-top: 20px;
  }
  .container-curso li:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .container-curso li:not(:nth-child(4n)) {
    margin-right: 20px;
  }
}
