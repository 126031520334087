@charset "UTF-8";
.container-loading {
  background-color: #fff;
  border-radius: 4px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9; }
  .container-loading .loading {
    color: #4d4d4d;
    align-items: center;
    text-align: center;
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 16px; }

.container-loading-login {
  background-color: rgba(0, 0, 0, 0.2); }

.nome {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: #fafafa;
  background-color: #02022e;
  text-align: center;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  font-size: 30px;
  justify-content: space-between; }

.lista {
  display: flex; }

.myitem {
  color: #fafafa; }

.nav-titulo-video {
  color: #4d4d4d;
  align-items: center;
  font-size: 1.5em;
  display: flex; }
  .nav-titulo-video i {
    font-size: 1.4em; }

.collection {
  z-index: 999;
  transition: width 0.17s;
  width: 300px;
  position: fixed;
  top: 0;
  color: #aeaeae;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #181c1f;
  min-height: 100vh;
  max-height: 100vh;
  list-style-type: none;
  padding: 0 0 0 0.1px;
  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: column; }

.collection .container-logo-ga {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  padding: 24px;
  width: 100%; }
  .collection .container-logo-ga a {
    display: block; }
  .collection .container-logo-ga .container-logo-ga-icon-back {
    margin-right: 20px; }
  .collection .container-logo-ga .container-logo-ga-hamburguer {
    margin-left: auto;
    font-size: 3em;
    cursor: pointer; }

.collection .collection-item i {
  margin-right: 15px;
  color: #aeaeae;
  font-size: 2em; }

.collection .MuiButton-root {
  color: #aeaeae; }

.collection .list-test {
  margin-top: auto; }

.collection .collection-item.myitem {
  background-color: #282c2f; }

.collection .collection-item .MuiList-padding {
  padding-top: 0;
  padding-bottom: 0; }
  .collection .collection-item .MuiList-padding .MuiListItem-button:hover {
    background-color: rgba(14, 14, 14, 0.4); }
  .collection .collection-item .MuiList-padding .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0; }
  .collection .collection-item .MuiList-padding .MuiListItem-gutters {
    padding: 8px 0;
    padding-right: 24px;
    padding-left: 24px; }
    .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root {
      display: block; }
      .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root span {
        transition: width 0.35s;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        width: 100%; }

.collection .list-video-item {
  font-size: 14px; }

.collection li {
  text-transform: capitalize; }

.buttonProva {
  padding: 12px 24px; }
  .buttonProva .MuiButton-text {
    padding: 6px 0; }
    .buttonProva .MuiButton-text:hover {
      background-color: transparent; }
  .buttonProva .MuiButton-label {
    justify-content: flex-start;
    align-items: center;
    display: flex; }
    .buttonProva .MuiButton-label label {
      font-size: 1em;
      cursor: pointer;
      transition: width 0.35s;
      white-space: nowrap;
      overflow: hidden;
      width: 100%; }
    .buttonProva .MuiButton-label .MuiSvgIcon-root {
      margin-right: 12px; }

.icon-question-true {
  position: absolute;
  font-size: 1.4em;
  left: -5px;
  top: 14px; }

.container-video-global {
  width: 100%; }

.container-video-global,
.nav-video,
.footer-video {
  margin-left: 300px;
  transition: left 0.17s; }

.nav-video .nav-menu-body {
  max-width: 1016px; }

.container-video {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 90%; }
  .container-video .react-player {
    width: 100% !important;
    height: 500px !important;
    display: flex; }
    .container-video .react-player .toolbar {
      display: none !important; }

.mobile-pdf {
  text-align: center;
  width: 40%;
  min-width: 250px;
  margin: 0 auto;
  background-color: #004473;
  border-radius: 4px;
  transition: all 0.35s; }
  .mobile-pdf a {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 15px 30px;
    font-size: 1.4em;
    color: #fff;
    font-weight: bold;
    text-decoration: none; }
  .mobile-pdf:hover {
    background-color: #1566a0; }

.container-description {
  max-width: 895.5px;
  width: 80%;
  margin: auto; }
  .container-description .container-description-title {
    align-items: center;
    padding: 32px 0;
    display: flex; }
    .container-description .container-description-title h4 {
      font-weight: bold;
      color: #4d4d4d;
      margin: 0; }
    .container-description .container-description-title hr {
      margin-left: 16px;
      height: 12px;
      width: calc(100% - 185px);
      background-color: #4d4d4d;
      border: none; }
  .container-description button i {
    margin-left: 10px; }
  .container-description .container-description-body {
    padding: 0 0 32px; }
    .container-description .container-description-body p {
      word-break: break-word; }

.video-ready {
  color: #008856 !important; }

.footer-video {
  margin-top: auto;
  width: initial; }

.menu-fechado .collection {
  width: 70px; }
  .menu-fechado .collection .container-logo-ga {
    margin: 0 auto; }
    .menu-fechado .collection .container-logo-ga .logo-ga {
      display: none; }
    .menu-fechado .collection .container-logo-ga .icon-size-md {
      display: none; }
    .menu-fechado .collection .container-logo-ga .container-logo-ga-hamburguer {
      margin: 6px 0px; }
  .menu-fechado .collection .collection-item i {
    margin-right: 12px;
    color: #aeaeae;
    font-size: 2em;
    margin: auto; }
  .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters {
    padding: 8px 0; }
    .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root {
      display: none; }
      .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root span {
        transition: width 0.1s;
        white-space: nowrap;
        overflow: hidden;
        width: 0; }

.menu-fechado .buttonProva {
  padding: 12px 0px; }
  .menu-fechado .buttonProva .MuiButton-text {
    padding: 6px 0;
    width: 100%; }
  .menu-fechado .buttonProva .MuiButton-label .MuiSvgIcon-root {
    margin: auto; }
  .menu-fechado .buttonProva .MuiButton-label label {
    transition: width 0.35s;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    font-size: 1em;
    width: 0; }

.menu-fechado .container-video-global,
.menu-fechado .nav-video,
.menu-fechado .footer-video {
  margin-left: 70px; }

.container-dialog-prova {
  padding: 12px 16px 0;
  overflow-y: auto;
  width: 100%; }
  .container-dialog-prova .MuiTypography-displayBlock {
    word-break: break-word;
    white-space: break-spaces; }

.titulo-avaliação,
.titulo-pages {
  font-family: "Segoe UI", "Nunito";
  color: #4d4d4d;
  font-weight: normal;
  margin-bottom: 16px;
  align-items: center;
  text-align: center;
  user-select: none;
  display: flex; }
  .titulo-avaliação hr,
  .titulo-pages hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #eff1f9; }
  .titulo-avaliação span,
  .titulo-pages span {
    min-width: max-content;
    font-size: 1.5rem;
    margin-top: 10px;
    color: #004473;
    font-weight: bold; }
  .titulo-avaliação strong,
  .titulo-pages strong {
    color: #004473;
    font-style: oblique; }
  .titulo-avaliação .barra,
  .titulo-pages .barra {
    font-weight: bold;
    margin: 0 16px; }
  .titulo-avaliação.titulo-pages,
  .titulo-pages.titulo-pages {
    padding: 0;
    margin: 10px 0 0 0; }

.enviar-respostas {
  flex-direction: row-reverse;
  justify-content: flex-start;
  display: flex; }

@media (min-width: 450px) {
  .container-video {
    width: 96%; } }

@media (min-width: 691px) {
  .container-video {
    width: 80%;
    max-width: 895.5px; } }

@media (max-width: 1024px) {
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 768px) {
  .container-video .react-player {
    width: 100% !important;
    height: 320px !important; }
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 690px) {
  .container-description {
    max-width: 1200px;
    width: 96%;
    margin: auto; } }

@media (max-width: 530px) {
  .container-description {
    width: 90%; }
  .template-video .nav-menu .nav-menu-body {
    flex-direction: column-reverse;
    justify-content: center; }
    .template-video .nav-menu .nav-menu-body .opcoes {
      margin-left: auto; }
  .container-video .react-player {
    width: 100% !important;
    height: 240px !important; } }
