@import "../css/variaveis.scss";

.tab-index-select {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  .tab-select {
    transition: 0.35s;
    transition-property: background-color, color;
    border: 1px solid $corBordaCurso;
    background-color: $corBordaCurso;
    transform: translateY(1px);
    text-transform: uppercase;
    -webkit-user-select: none;
    color: $corMenuPadrao;
    white-space: nowrap;
    text-align: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0 !important;
    border-right: transparent;
    display: block;
    padding: 12px;
    font-weight: bold;
    // border-bottom: 1px solid $corBordaInput;
    &:first-child {
      border-radius: 4px 0 0 0;
    }
    &:last-child {
      // border-right: 1px solid $corBordaCurso;
      border-radius: 0 4px 0 0;
    }
    &:hover {
      background-color: #2d71cc;
      border-color: $corBordaCurso;
      color: $corBranca;
      // border-bottom: 1px solid $corBordaInput;
    }
    opacity: 0.8;
  }
  .tab-select.tab-select-active {
    opacity: 1 !important;
    background-color: #2d71cc;
    color: $corBranca;
    transition: background-color, color 0.45s;
    border-right: 1px solid;
    border-bottom: transparent;
    border-color: $corTab;
    font-weight: bold;
  }
}

.tab-content {
  // border: 1px solid $corBordaInput;
  border-radius: 0 4px 4px;
  flex-direction: column;
  display: block;
  padding: 12px 0px;
}

@media (min-width: 768px) {
  .tab-index-select {
    overflow: initial;
  }
}
