.card-menu-container {
  // width: 150px;
  background-color: #fff;
  position: absolute;
  flex-direction: column;
  border: solid 1px #e0e0e0;
  align-items: flex-start;
  justify-content: center;
  a {
    word-break: keep-all;
    white-space: normal !important;
    width: 100%;
    color: #606060;
    padding: 10px;
    font-size: 14px;
    text-align: left;

    &:hover {
      background-color: #eff1f9;
      cursor: pointer;
    }
    i {
      margin-right: 2px;
      font-size: 18px !important;
    }
  }
}
