.detailsContentContainer{
    flex: 1;
    width: 100%;
    p{
        margin-top: 20px;
        margin-bottom: 20; 
    }
    
}
.titleImg{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    p{
      text-align: left !important;
    }    
    img{
        width: auto;
        height: 60px;
    }
}