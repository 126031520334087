.rdw-editor-main {
  height: 200px !important;
  background-color: #fdfcfc;
  border-width: 1;
}

.warning-corpo-email {
  font-size: 12px;
  color: #ff6b79;
}
