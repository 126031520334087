.box-profile {
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.completed-course, .icon{
  margin: auto;
}

.icon {
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
  background-color: #fff;
  justify-content: center;
  transition: all 0.35s;
  border-radius: 4px;
  align-items: center;
  padding: 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-bottom: 32px;
}

.icon:hover {
  box-shadow: 1px 2px 2px #b7b7b71a;
}

.email {
  justify-content: center;
  display: flex;
  font-size: 2em;
}

.completed-course {
  background-color: #fff;
  justify-content: center;
  transition: all 0.35s;
  border-radius: 4px;
  align-items: center;
  padding: 12px;
  width: 100%;
  display: flex;
  font-size: 2em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.completed-course-number {
  background-color: #647fa9;
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.completed-course-name {
  margin-left: 12px;
}

@media (min-width: 550px) {

  .completed-course, .icon{
    width: max-content;
    margin: 0;
  }

  .icon {
    margin-bottom: 0;
  }
}
