@import "../css/variaveis.scss";

.container-loading {
  background-color: #f9fbfd;
  border-radius: 4px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  .loading {
    color: $corMenuPadrao;
    align-items: center;
    text-align: center;
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $corBranca;
    border-radius: 4px;
    padding: 16px;
  }
}
.container-loading-login {
  background-color: rgba(0, 0, 0, 0.2);
}

.lista {
  display: flex;
}

.nav-titulo-video {
  color: $corMenuPadrao;
  align-items: center;
  font-size: 1.5em;
  display: flex;
  i {
    font-size: 1.4em;
  }
}

.collection-menu-lateral {
  z-index: 999;
  transition: width 0.17s;
  width: 300px;
  position: fixed;
  top: 0;
  color: #4d4d4d;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffff;
  min-height: 100vh;
  max-height: 100vh;
  list-style-type: none;
  padding: 0 0 0 0.1px;
  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-right: solid 0.7px #dedede;
  box-shadow: 0px 0px 0px 0px #00000005, 0px 0px 4px 0px #00000024,
    0px 0px 0px 0px #0000000a;
}

// Here

.collection-menu-lateral {
  .container-logo-ga {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    padding: 24px;
    width: 100%;
    a {
      display: block;
    }

    .container-logo-ga-hamburguer {
      margin-left: auto;
      font-size: 2.4em;
      cursor: pointer;
    }
  }

  .collection-menu-lateral-item {
    .list-item {
      width: 100%;
      display: flex;
      padding: 7px 10px 7px 24px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    a {
      font-size: 1.6em;
      color: $corTextoPadrao;
    }
    i {
      margin-right: 15px;
      color: #4d4d4d;
      font-size: 2.2em;
    }
    .item-active {
      opacity: 0.8 !important;
      background-color: #2d72cc11 !important;
      a,
      i {
        color: #2d71cc !important;
      }
    }
  }
}
.collection-menu-lateral-item:hover {
  opacity: 0.8;
  background-color: #2d72cc11;
  a,
  i {
    color: #2d71cc;
  }
}
.list-sub-menu {
  a {
    margin-left: 36px;
  }
  .item-active {
    :hover {
      opacity: 0.8;
      background-color: #2d72cc63;
      a,
      i {
        color: #fff;
      }
    }
  }
}

.container-video-global {
  width: 100%;
}

.container-video-global,
.nav-video,
.footer-video {
  margin-left: 300px;
  transition: left 0.17s;
}

.nav-video {
  .nav-menu-body {
    max-width: 1016px;
  }
}

// CSS MENU FECHADO

.menu-fechado {
  .collection-menu-lateral {
    width: 70px;
    .container-logo-ga {
      margin: 0 auto;
      .logo-ga {
        display: none;
      }
      .icon-size-md {
        display: none;
      }
      .container-logo-ga-hamburguer {
        margin: 6px 0px;
      }
    }
    .collection-menu-lateral-item {
      a,
      span {
        display: none;
      }
    }
    .list-sub-menu {
      display: none;
    }
  }

  // Body
}

// Fim Avaliação

@media (min-width: 450px) {
  .container-video {
    width: 96%;
  }
}
@media (min-width: 691px) {
  .container-video {
    width: 80%;
    max-width: 895.5px;
  }
}

@media (max-width: 1024px) {
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s;
  }
}

@media (max-width: 768px) {
  .search {
    display: none;
  }
  .container-video {
    .react-player {
      width: 100% !important;
      height: 320px !important;
    }
  }

  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s;
  }
}

@media (max-width: 690px) {
  .container-description {
    max-width: 1200px;
    width: 96%;
    margin: auto;
  }
}

@media (max-width: 530px) {
  .container-description {
    width: 90%;
  }
  .template-video {
    .nav-menu {
      .nav-menu-body {
        flex-direction: column-reverse;
        justify-content: center;
        .opcoes {
          margin-left: auto;
        }
      }
    }
  }

  .container-video {
    .react-player {
      width: 100% !important;
      height: 240px !important;
    }
  }
}
