.dialog-nota .MuiDialog-paperWidthSm{
   padding-bottom: 0;
}

.notas{
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   padding: 15px;
}

.notas:last-child{
   margin-bottom: 0;
}