.containerCard {
  display: flex;
  flex-direction: column;
  padding: 1px;
  align-items: center;
  justify-content: center;

  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
  border: solid 1px #eff1f9;
  background-color: #fff;
  transition: box-shadow 0.35s;
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  .containerTitle {
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  span {
    padding: 2px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #606060;
    font-size: 1.1em;
  }
  .title {
    font-size: 1.5em;
  }
}
