.signatureCardContainer{
    display: flex;
    flex-direction: column;
    width: 130px;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
}

.signatureCardContainerImg{
    display: flex;
    width: 130px;
    height: 60px;
    justify-content: center;
    img{
        width: auto;
        height: 60px;
    }
}


.signatureCardContainerLabel{
    width: 100%;
    align-items: center;
    border-top: 0.5px solid #657176;
    p{
        font-size: 12px;
        text-align: center;
        color: #657176
      
    }
}
