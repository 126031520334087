@import "variaveis.scss";
@import "fonts.scss";

* {
  box-sizing: border-box;
  outline: none;
}

::-webkit-scrollbar {
  background: #e0e0e0;
  height: 4px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #bebebe;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

html,
body {
  height: 100%;
}
.background-notfound {
  background: url("../../components/img/PageNotFound.jpg") center no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 100%;
  .container {
    margin: auto;
  }
}

body {
  background-color: $backgroundPadrao;
  font-family: $fontPadrao;
  box-sizing: border-box;
  flex-direction: column;
  font-size: 62.5%;
  display: flex;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2em;
}

p {
  font-size: 1.6em;
  padding: 0;
  margin: 0;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.Toastify__toast {
  box-shadow: $boxShadowPadrao !important;
  font-family: "Nunito" !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-size: 1.6em !important;
  display: flex !important;
  .Toastify__close-button {
    margin: auto !important;
    font-size: 1.2em;
  }
}

.nav-menu {
  background-color: $backgroundMenuPadrao;
  .nav-menu-body {
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    display: flex;
    #ButtonName {
      color: $corMenuPadrao;
      cursor: pointer;
    }
  }
}

.background-interno {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.form-cadastros {
  label {
    margin-bottom: 12px;
    display: block;
  }
  textarea {
    resize: none;
    height: 130px;
  }
}

.inputReadOnly {
  border: 1px solid $corBordaInput;
  display: flex;
  justify-content: space-between;
  font-family: $fontPadrao;
  background: $corBranca;
  color: $corTextoPadrao;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1.6em;
  width: 100%;
  outline: 0;
  cursor: pointer;
  height: 44px;

  a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    color: $iconModColorReady;
    &:hover {
      opacity: 0.8;
    }
  }
}

.inputPassword {
  display: flex;
  justify-content: right;
  background: $corBranca;
  border-radius: 4px;
  width: 100%;
  outline: 0;
  cursor: pointer;
  height: 44px;
  align-items: center;
  position: relative;
  left: 10px;
  input {
    position: absolute;
    border: 1px solid $corBordaInput;
    font-family: $fontPadrao;
    color: $corTextoPadrao;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-right: 10px;
    font-size: 1.6em;
    width: "100%";
    height: "100%";
  }

  span {
    right: 20px;
    position: absolute;
    color: $corTextoPadrao;

    &:hover {
      opacity: 0.99;
    }
  }
}

input,
textarea,
select {
  border: 1px solid $corBordaInput;
  font-family: $fontPadrao;
  background: $corBranca;
  color: $corTextoPadrao;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1.6em;
  width: 100%;
  outline: 0;
}

input[type="color" i] {
  cursor: pointer;
  height: 44px;
}

label {
  font-size: 1.6em;
}

button {
  background-color: $botaoPrimario;
  font-family: $fontPadrao;
  transition: all 0.35s;
  border-radius: 4px;
  padding: 12px 32px;
  margin-left: auto;
  color: $corBranca;
  font-size: 1.6em;
  cursor: pointer;
  display: block;
  border: none;
  &:hover {
    background-color: $botaoPrimarioHover;
  }
}
.sequence-page {
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  a {
    cursor: pointer;
    color: #004473;
  }
  i {
    font-size: 17px;
  }
  span {
    opacity: 0.8;
  }
  a:hover {
    text-decoration: underline;
  }
}

.Toastify {
  button {
    &:hover {
      background-color: transparent;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.icon-information {
  cursor: pointer;
  .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: $corMenuPadrao;
    color: #fff;
    text-align: left;
    font-size: 14px;
    border-radius: 7px;
    padding: 5px 5px;
    position: absolute;

    /* Position the tooltip */
    z-index: 1;
  }
  span {
    font-family: $fontPadrao !important;
  }
}

.icon-information:hover .tooltiptext {
  visibility: visible;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2d71cc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2d71cc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}

.botao-destaque {
  background-color: $corDestaque;
  color: $corTextoDestaque;
  font-weight: bold;
  margin-left: 0;
  &:hover {
    background-color: $corDestaqueHover;
  }
}

.logo-ga {
  max-width: 125px;
  max-height: 60px;
  width: auto;
}

.largura-padrao {
  max-width: 1312px;
  margin: 0 auto;
  width: 94%;
}

.select-input-option-hiden {
  option {
    &:first-child {
      display: none !important;
    }
  }
}

.tn-50 {
  max-width: 700px;
}

.UsuarioLista {
  margin: auto;
}

footer {
  text-align: center;
  padding: 24px 12px;
  margin-top: auto;
  width: 100%;
  bottom: 0;
  p {
    color: $corMenuPadrao;
    font-size: 1.6em;
    margin: 0;
  }
}

#root {
  flex-direction: column;
  position: relative;
  display: flex;
  height: 100%;
}

.file-content {
  .label-files {
    justify-content: space-between;
    background-color: $corBranca;
    border: 1px solid $corTab;
    transition: all 0.35s;
    white-space: nowrap;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    width: max-content;
    padding: 6px 8px;
    display: flex;
    width: 100%;
    label {
      margin: 0;
      &:first-child {
        border-radius: 4px;
        padding: 4px 6px;
        cursor: pointer;
      }
      &:last-child {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 0.5em;
      }
    }
  }
}
.dialog-box-input {
  label {
    margin-bottom: 6px;
  }

  input {
    height: 47px;
  }
}

.file-content input[type="file"] {
  display: none;
}

.primary-button-file {
  background-color: $buttonFile;
  border: 1px solid $corTab;
}

@media screen and (max-width: 630px) {
  .nav-menu {
    margin-bottom: 32px;
  }
  .form-cadastros {
    .form-flex div {
      margin-right: 0;
      width: 100%;
    }
  }
  .form-cadastros {
    .form-flex {
      flex-wrap: wrap;
    }
  }
}
