.HeaderLogoContainer{
    width: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding: 0 40px;
}

.headerLogo{
    width: auto;
    height: 70%;
}