@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Diploma';
    src: url('../fonts/diploma.ttf') format('truetype');
}


@font-face {
    font-family: 'Gar-A-MondTall';
    src: url('../fonts/Gar-A-MondTall.ttf') format('truetype');
}


@font-face {
    font-family: 'Learning-Curve';
    src: url('../fonts/learning_curve_regular_ot_tt.ttf') format('truetype');
}