.Toastify__toast--error {
	background: #FF6B79 !important;
}

.Toastify__toast--success{
	background-color: #33D275 !important;
}

.Toastify__toast--warn{
	background-color: #FFC95C !important;
}

.Toastify__toast--info{
	background-color:#5CC4EB !important;
}