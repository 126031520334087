@import "../../css/variaveis.scss";

body {
  background-size: cover;
}

// Menu NAVBAR

#simple-menu .MuiMenu-paper {
  box-shadow: $boxShadowPadrao !important;
  left: initial !important;
  width: 250px !important;
  right: 9% !important;
  top: 72px !important;
  ul {
    display: flex !important;
    flex-direction: column !important;
    a {
      box-sizing: border-box;
      color: $corMenuPadrao;
      text-decoration: none;
      align-items: center;
      padding: 10px 16px;
      font-size: 1.6em;
      cursor: pointer;
      display: flex;
      i {
        color: $corTextoPadrao;
        margin-right: 4px;
        font-size: 1.2em;
      }
      &:hover {
        background-color: #f8f8f8;
        color: $corTextoPadrao;
        transition: all 0.35s;
      }
    }
  }
}

// Fim Menu NAVBAR

.background-dashboard {
  background: url("../../../components/img/background-dashboard.svg") center
    no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 75vh;
  max-height: 600px;
  .container {
    margin: auto;
  }
}

.background-principal {
  background: url("../../../components/img/background-logos.svg") center
    no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 100%;
  .container {
    margin: auto;
  }
}

.menu-dash {
  background: none;
  .nav-menu-body {
    #ButtonName {
      color: $corBranca;
    }
  }
}
.menu-options {
  display: flex;
  align-items: center;
  justify-content: center;
  .search {
    margin-right: 10px;
    .box {
      position: relative;
    }
    .input {
      width: 70px;
      height: 40px;
      background: none;
      // border-radius: 50px;
      box-sizing: border-box;
      outline: none;
      transition: 0.9s;
    }
    // .box:hover input {
    //   width: 300px;
    //   background: $corBranca;
    //   border-radius: 10px;
    // }
    .box i {
      color: #4d4d4d;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(-50%, -50%);
      font-size: 26px;
      transition: 0.2s;
      cursor: pointer;
    }
    // .box:hover i {
    //   opacity: 0;
    //   z-index: -1;
    // }
  }
}

.container-header {
  min-height: calc(100% - 159px);
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 100%;
}

.container-header {
  flex-wrap: wrap-reverse;
  .container-sistemas {
    height: 182px;
  }
  .container-texto {
    height: auto;
  }
  .container-sistemas {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    #video-intro-container {
      position: relative;
      height: 100%;
      z-index: 2;
      iframe {
        border-radius: 2px;
        box-shadow: $boxShadowPadrao;
      }
      &::before {
        content: "";
        border-radius: 2px;
        background-color: transparent;
        border: 1px solid $corBranca;
        position: absolute;
        height: 100%;
        z-index: -1;
        width: 100%;
        transition: all 0.35s;
        left: -7px;
        top: -7px;
      }
      &:hover::before {
        left: -1px;
        top: -1px;
      }
      #video-intro {
        height: 100% !important;
        width: 100% !important;
      }
    }
    #img-intro-container {
      position: relative;
      height: 100%;
      z-index: 2;
      #svg-into {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  .container-texto {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    h4,
    p {
      text-align: center;
      color: $corBranca;
    }
    h4 {
      font-size: 2.4em;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 2em;
    }
    button {
      margin: auto;
    }
    h4,
    p,
    button {
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 1440px) {
  #simple-menu .MuiMenu-paper {
    right: 4% !important;
  }
}

@media screen and (max-width: 1400px) {
  #simple-menu .MuiMenu-paper {
    right: 3% !important;
  }
}

@media (min-width: 770px) {
  .container-header {
    .container-sistemas,
    .container-texto {
      height: 250px;
    }
    .container-sistemas {
      width: 50%;
    }
    .container-texto {
      h4 {
        text-align: left;
        font-size: 2.4em;
      }
      p {
        text-align: left;
        font-size: 2em;
      }
      h4,
      p,
      button {
        margin-bottom: 0;
        margin-top: 0;
      }
      button {
        margin-left: 0;
      }
    }
  }
  .container-texto {
    width: 50%;
  }
}

@media (min-width: 731px) {
  .container-header {
    .container-sistemas {
      #video-intro-container {
        margin-left: auto;
        max-width: 450px;
        height: 100%;
        z-index: 2;
        iframe {
          border-radius: 2px;
          box-shadow: $boxShadowPadrao;
        }
      }
      #img-intro-container {
        max-width: 500px;
        height: 100%;
        z-index: 2;
      }
    }
  }
}

@media (max-width: 400px) {
  .container-sistemas {
    max-width: 200px;
  }
}

@media (max-width: 960px) {
  .container-sistemas {
    max-width: 300px;
  }
}

@media (min-width: 960px) {
  .container-header {
    .container-sistemas {
      width: 55%;
      #video-intro-container {
        margin-left: auto;
        width: 450px;
        height: 100%;
        z-index: 2;
        iframe {
          border-radius: 2px;
          box-shadow: $boxShadowPadrao;
        }
      }
      #img-intro-container {
        margin-left: auto;
        width: auto;
        height: 100%;
        z-index: 2;
      }
    }
    .container-texto {
      display: initial;
      width: 45%;
      h4 {
        text-align: left;
        font-size: 2.4em;
      }
      p {
        text-align: left;
        margin: 32px 0 42px;
        font-size: 2em;
      }
    }
  }
}
