.alternativas{
    flex-direction: column;
    display: flex;
}
.teste1:first-child {
  order: 4;
}
.teste2:first-child {
  order: 3;
}
.teste3:first-child {
  order: 2;
}
.teste4:first-child {
  order: 1;
}
