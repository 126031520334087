// Cores
$corBranca: #fff;
$corTextoPadrao: #606060;
$corMenuPadrao: #4d4d4d;

// Input
$corBordaInput: #d2d2d2;

// Card Cursos
$corTituloCursos: #f7f8fb;
$corBordaCurso: #eff1f9;

// Icon Mudule

$iconModColorReady: rgb(18, 150, 18);
$iconModColorBlock: #962222;
$iconModColor: #000;

// Tab Select

$corTab: #d2d2d2;
$buttonFile: #f1f1f1;

// Tabela Padrão

$corTituloTabela: #2d71cc;
$corBordaTabela: #f2f2f2;
$corDeleteTable: #ff6b79;

// Font
$fontPadrao: "Nunito";

// Formulário Cadastros
$corTituloForm: #f8f8f8;

// Botao
$botaoPrimario: #004473;
$botaoPrimarioHover: #1566a0;

// Cor Menu Padrão

$backgroundMenuPadrao: #fff;

// Cor Background

$backgroundPadrao: #f9fbfd;
$backgroundMobile: #0085d2;

// Box shadow

$boxShadowPadrao: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
$boxShadowMenu: 1px 2px 6px #b7b7b71a;

// Cor Slick

$corSlick: #008288;

// corDestaque

$corDestaque: #fff500;
$corDestaqueHover: #d4cf4f;

$corTextoDestaque: #004a6e;
