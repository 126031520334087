.Toastify__toast--error {
	background: #FF6B79 !important;
}

.Toastify__toast--success{
	background-color: #33D275 !important;
}

.Toastify__toast--warn{
	background-color: #FFC95C !important;
}

.Toastify__toast--info{
	background-color:#5CC4EB !important;
}
.signatureCardContainer {
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 60px;
  align-items: center;
  justify-content: flex-end; }

.signatureCardContainerImg {
  display: flex;
  width: 130px;
  height: 60px;
  justify-content: center; }
  .signatureCardContainerImg img {
    width: auto;
    height: 60px; }

.signatureCardContainerLabel {
  width: 100%;
  align-items: center;
  border-top: 0.5px solid #657176; }
  .signatureCardContainerLabel p {
    font-size: 12px;
    text-align: center;
    color: #657176; }

.headerBackgroundContainer {
  width: 100%;
  height: 100%; }

.HeaderLogoContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding: 0 40px; }

.headerLogo {
  width: auto;
  height: 70%; }

.cardTitleContainer {
  padding: 10px 10px; }

.contentCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 40px; }
  .contentCardContainer p {
    margin-bottom: 10px; }

.footerBackground {
  width: 100%;
  height: 100%; }

.footerTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 40px;
  overflow: hidden; }

.detailsContentContainer {
  flex: 1 1;
  width: 100%; }
  .detailsContentContainer p {
    margin-top: 20px;
    margin-bottom: 20; }

.titleImg {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between; }
  .titleImg p {
    text-align: left !important; }
  .titleImg img {
    width: auto;
    height: 60px; }

.variableContainer {
  margin: 0 20px; }

.tr-form-group {
  margin-bottom: 1.5rem !important; }

.tr-row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -12px;
  margin-left: -12px; }

.tr-col-xl,
.tr-col-xl-1,
.tr-col-xl-2,
.tr-col-xl-3,
.tr-col-xl-4,
.tr-col-xl-5,
.tr-col-xl-6,
.tr-col-xl-7,
.tr-col-xl-8,
.tr-col-xl-9,
.tr-col-xl-10,
.tr-col-xl-11,
.tr-col-xl-12,
.tr-col-lg,
.tr-col-lg-1,
.tr-col-lg-2,
.tr-col-lg-3,
.tr-col-lg-4,
.tr-col-lg-5,
.tr-col-lg-6,
.tr-col-lg-7,
.tr-col-lg-8,
.tr-col-lg-9,
.tr-col-lg-10,
.tr-col-lg-11,
.tr-col-lg-12,
.tr-col-md,
.tr-col-md-1,
.tr-col-md-2,
.tr-col-md-3,
.tr-col-md-4,
.tr-col-md-5,
.tr-col-md-6,
.tr-col-md-7,
.tr-col-md-8,
.tr-col-md-9,
.tr-col-md-10,
.tr-col-md-11,
.tr-col-md-12,
.tr-col-sm,
.tr-col-sm-1,
.tr-col-sm-2,
.tr-col-sm-3,
.tr-col-sm-4,
.tr-col-sm-5,
.tr-col-sm-6,
.tr-col-sm-7,
.tr-col-sm-8,
.tr-col-sm-9,
.tr-col-sm-10,
.tr-col-sm-11,
.tr-col-sm-12,
.tr-col,
.tr-col-1,
.tr-col-2,
.tr-col-3,
.tr-col-4,
.tr-col-5,
.tr-col-6,
.tr-col-7,
.tr-col-8,
.tr-col-9,
.tr-col-10,
.tr-col-11,
.tr-col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px; }

.tr-col {
  flex-basis: 0;
  flex-grow: 1;
  -webkit-box-flex: 1;
  max-width: 100%;
  box-sizing: border-box; }

.tr-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.tr-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.tr-col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.tr-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.tr-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.tr-col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.tr-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.tr-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.tr-col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.tr-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.tr-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.tr-col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

@media screen and (min-width: 576px) {
  .tr-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box; }
  .tr-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .tr-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .tr-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .tr-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .tr-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .tr-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .tr-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .tr-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .tr-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .tr-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .tr-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .tr-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 768px) {
  .tr-col-md {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box; }
  .tr-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .tr-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .tr-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .tr-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .tr-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .tr-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .tr-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .tr-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .tr-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .tr-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .tr-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .tr-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 992px) {
  .tr-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box; }
  .tr-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .tr-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .tr-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .tr-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .tr-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .tr-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .tr-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .tr-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .tr-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .tr-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .tr-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .tr-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 1200px) {
  .tr-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box; }
  .tr-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .tr-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .tr-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .tr-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .tr-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .tr-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .tr-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .tr-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .tr-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .tr-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .tr-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .tr-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

.tr-m-0 {
  margin: 0 !important; }

.tr-mt-0 {
  margin-top: 0 !important; }

.tr-mr-0 {
  margin-right: 0 !important; }

.tr-mb-0 {
  margin-bottom: 0 !important; }

.tr-ml-0 {
  margin-left: 0 !important; }

.tr-m-1 {
  margin: 0.25rem !important; }

.tr-mt-1 {
  margin-top: 0.25rem !important; }

.tr-mr-1 {
  margin-right: 0.25rem !important; }

.tr-mb-1 {
  margin-bottom: 0.25rem !important; }

.tr-ml-1 {
  margin-left: 0.25rem !important; }

.tr-m-2 {
  margin: 0.5rem !important; }

.tr-mt-2 {
  margin-top: 0.5rem !important; }

.tr-mr-2 {
  margin-right: 0.5rem !important; }

.tr-mb-2 {
  margin-bottom: 0.5rem !important; }

.tr-ml-2 {
  margin-left: 0.5rem !important; }

.tr-m-3 {
  margin: 1rem !important; }

.tr-mt-3 {
  margin-top: 1rem !important; }

.tr-mr-3 {
  margin-right: 1rem !important; }

.tr-mb-3 {
  margin-bottom: 1rem !important; }

.tr-ml-3 {
  margin-left: 1rem !important; }

.tr-m-4 {
  margin: 1.5rem !important; }

.tr-mt-4 {
  margin-top: 1.5rem !important; }

.tr-mr-4,
.tr-mx-4 {
  margin-right: 1.5rem !important; }

.tr-mb-4 {
  margin-bottom: 1.5rem !important; }

.tr-ml-4 {
  margin-left: 1.5rem !important; }

.tr-m-5 {
  margin: 3rem !important; }

.tr-mt-5 {
  margin-top: 3rem !important; }

.tr-mr-5 {
  margin-right: 3rem !important; }

.tr-mb-5 {
  margin-bottom: 3rem !important; }

.tr-ml-5 {
  margin-left: 3rem !important; }

.tr-dialog-sm,
.tr-dialog-md,
.tr-dialog-lg {
  width: 100%; }

.tr-display-flex {
  display: flex !important; }

.tr-space-between {
  justify-content: space-between !important; }

.tr-flex-end {
  justify-content: flex-end !important; }

.tr-align-itens {
  align-items: center !important; }

@media (min-width: 768px) {
  .tr-dialog-sm {
    width: 400px; }
  .tr-dialog-md {
    width: 600px; }
  .tr-dialog-lg {
    width: 800px; } }

.tab-index-select {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex; }
  .tab-index-select .tab-select {
    transition: 0.35s;
    transition-property: background-color, color;
    border: 1px solid #eff1f9;
    background-color: #eff1f9;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    text-transform: uppercase;
    -webkit-user-select: none;
    color: #4d4d4d;
    white-space: nowrap;
    text-align: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0 !important;
    border-right: transparent;
    display: block;
    padding: 12px;
    font-weight: bold;
    opacity: 0.8; }
    .tab-index-select .tab-select:first-child {
      border-radius: 4px 0 0 0; }
    .tab-index-select .tab-select:last-child {
      border-radius: 0 4px 0 0; }
    .tab-index-select .tab-select:hover {
      background-color: #2d71cc;
      border-color: #eff1f9;
      color: #fff; }
  .tab-index-select .tab-select.tab-select-active {
    opacity: 1 !important;
    background-color: #2d71cc;
    color: #fff;
    transition: background-color, color 0.45s;
    border-right: 1px solid;
    border-bottom: transparent;
    border-color: #d2d2d2;
    font-weight: bold; }

.tab-content {
  border-radius: 0 4px 4px;
  flex-direction: column;
  display: block;
  padding: 12px 0px; }

@media (min-width: 768px) {
  .tab-index-select {
    overflow: initial; } }

.container-loading {
  background-color: #f9fbfd;
  border-radius: 4px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9; }
  .container-loading .loading {
    color: #4d4d4d;
    align-items: center;
    text-align: center;
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 16px; }

.container-loading-login {
  background-color: rgba(0, 0, 0, 0.2); }

.lista {
  display: flex; }

.nav-titulo-video {
  color: #4d4d4d;
  align-items: center;
  font-size: 1.5em;
  display: flex; }
  .nav-titulo-video i {
    font-size: 1.4em; }

.collection-menu-lateral {
  z-index: 999;
  transition: width 0.17s;
  width: 300px;
  position: fixed;
  top: 0;
  color: #4d4d4d;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffff;
  min-height: 100vh;
  max-height: 100vh;
  list-style-type: none;
  padding: 0 0 0 0.1px;
  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-right: solid 0.7px #dedede;
  box-shadow: 0px 0px 0px 0px #00000005, 0px 0px 4px 0px #00000024, 0px 0px 0px 0px #0000000a; }

.collection-menu-lateral .container-logo-ga {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  padding: 24px;
  width: 100%; }
  .collection-menu-lateral .container-logo-ga a {
    display: block; }
  .collection-menu-lateral .container-logo-ga .container-logo-ga-hamburguer {
    margin-left: auto;
    font-size: 2.4em;
    cursor: pointer; }

.collection-menu-lateral .collection-menu-lateral-item .list-item {
  width: 100%;
  display: flex;
  padding: 7px 10px 7px 24px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }

.collection-menu-lateral .collection-menu-lateral-item a {
  font-size: 1.6em;
  color: #606060; }

.collection-menu-lateral .collection-menu-lateral-item i {
  margin-right: 15px;
  color: #4d4d4d;
  font-size: 2.2em; }

.collection-menu-lateral .collection-menu-lateral-item .item-active {
  opacity: 0.8 !important;
  background-color: #2d72cc11 !important; }
  .collection-menu-lateral .collection-menu-lateral-item .item-active a,
  .collection-menu-lateral .collection-menu-lateral-item .item-active i {
    color: #2d71cc !important; }

.collection-menu-lateral-item:hover {
  opacity: 0.8;
  background-color: #2d72cc11; }
  .collection-menu-lateral-item:hover a,
  .collection-menu-lateral-item:hover i {
    color: #2d71cc; }

.list-sub-menu a {
  margin-left: 36px; }

.list-sub-menu .item-active :hover {
  opacity: 0.8;
  background-color: #2d72cc63; }
  .list-sub-menu .item-active :hover a,
  .list-sub-menu .item-active :hover i {
    color: #fff; }

.container-video-global {
  width: 100%; }

.container-video-global,
.nav-video,
.footer-video {
  margin-left: 300px;
  transition: left 0.17s; }

.nav-video .nav-menu-body {
  max-width: 1016px; }

.menu-fechado .collection-menu-lateral {
  width: 70px; }
  .menu-fechado .collection-menu-lateral .container-logo-ga {
    margin: 0 auto; }
    .menu-fechado .collection-menu-lateral .container-logo-ga .logo-ga {
      display: none; }
    .menu-fechado .collection-menu-lateral .container-logo-ga .icon-size-md {
      display: none; }
    .menu-fechado .collection-menu-lateral .container-logo-ga .container-logo-ga-hamburguer {
      margin: 6px 0px; }
  .menu-fechado .collection-menu-lateral .collection-menu-lateral-item a,
  .menu-fechado .collection-menu-lateral .collection-menu-lateral-item span {
    display: none; }
  .menu-fechado .collection-menu-lateral .list-sub-menu {
    display: none; }

@media (min-width: 450px) {
  .container-video {
    width: 96%; } }

@media (min-width: 691px) {
  .container-video {
    width: 80%;
    max-width: 895.5px; } }

@media (max-width: 1024px) {
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 768px) {
  .search {
    display: none; }
  .container-video .react-player {
    width: 100% !important;
    height: 320px !important; }
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 690px) {
  .container-description {
    max-width: 1200px;
    width: 96%;
    margin: auto; } }

@media (max-width: 530px) {
  .container-description {
    width: 90%; }
  .template-video .nav-menu .nav-menu-body {
    flex-direction: column-reverse;
    justify-content: center; }
    .template-video .nav-menu .nav-menu-body .opcoes {
      margin-left: auto; }
  .container-video .react-player {
    width: 100% !important;
    height: 240px !important; } }

.texto{
    margin-bottom: 1%;
   
}
@font-face {
  font-family: 'Nunito';
  src: url(/static/media/Nunito-Regular.d2e691bc.ttf) format("truetype"); }

@font-face {
  font-family: 'Diploma';
  src: url(/static/media/diploma.600d4354.ttf) format("truetype"); }

@font-face {
  font-family: 'Gar-A-MondTall';
  src: url(/static/media/Gar-A-MondTall.21f120c9.ttf) format("truetype"); }

@font-face {
  font-family: 'Learning-Curve';
  src: url(/static/media/learning_curve_regular_ot_tt.6409494a.ttf) format("truetype"); }

* {
  box-sizing: border-box;
  outline: none; }

::-webkit-scrollbar {
  background: #e0e0e0;
  height: 4px;
  width: 6px; }

::-webkit-scrollbar-thumb {
  background: #bebebe; }

::-webkit-scrollbar-track {
  background-color: #e0e0e0; }

html,
body {
  height: 100%; }

.background-notfound {
  background: url(/static/media/PageNotFound.237c3101.jpg) center no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 100%; }
  .background-notfound .container {
    margin: auto; }

body {
  background-color: #f9fbfd;
  font-family: "Nunito";
  box-sizing: border-box;
  flex-direction: column;
  font-size: 62.5%;
  display: flex;
  padding: 0;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2em; }

p {
  font-size: 1.6em;
  padding: 0;
  margin: 0; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.Toastify__toast {
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1) !important;
  font-family: "Nunito" !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-size: 1.6em !important;
  display: flex !important; }
  .Toastify__toast .Toastify__close-button {
    margin: auto !important;
    font-size: 1.2em; }

.nav-menu {
  background-color: #fff; }
  .nav-menu .nav-menu-body {
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    display: flex; }
    .nav-menu .nav-menu-body #ButtonName {
      color: #4d4d4d;
      cursor: pointer; }

.background-interno {
  flex-direction: column;
  display: flex;
  height: 100%; }

.form-cadastros label {
  margin-bottom: 12px;
  display: block; }

.form-cadastros textarea {
  resize: none;
  height: 130px; }

.inputReadOnly {
  border: 1px solid #d2d2d2;
  display: flex;
  justify-content: space-between;
  font-family: "Nunito";
  background: #fff;
  color: #606060;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1.6em;
  width: 100%;
  outline: 0;
  cursor: pointer;
  height: 44px; }
  .inputReadOnly a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .inputReadOnly span {
    color: #129612; }
    .inputReadOnly span:hover {
      opacity: 0.8; }

.inputPassword {
  display: flex;
  justify-content: right;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  outline: 0;
  cursor: pointer;
  height: 44px;
  align-items: center;
  position: relative;
  left: 10px; }
  .inputPassword input {
    position: absolute;
    border: 1px solid #d2d2d2;
    font-family: "Nunito";
    color: #606060;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-right: 10px;
    font-size: 1.6em;
    width: "100%";
    height: "100%"; }
  .inputPassword span {
    right: 20px;
    position: absolute;
    color: #606060; }
    .inputPassword span:hover {
      opacity: 0.99; }

input,
textarea,
select {
  border: 1px solid #d2d2d2;
  font-family: "Nunito";
  background: #fff;
  color: #606060;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1.6em;
  width: 100%;
  outline: 0; }

input[type="color" i] {
  cursor: pointer;
  height: 44px; }

label {
  font-size: 1.6em; }

button {
  background-color: #004473;
  font-family: "Nunito";
  transition: all 0.35s;
  border-radius: 4px;
  padding: 12px 32px;
  margin-left: auto;
  color: #fff;
  font-size: 1.6em;
  cursor: pointer;
  display: block;
  border: none; }
  button:hover {
    background-color: #1566a0; }

.sequence-page {
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .sequence-page a {
    cursor: pointer;
    color: #004473; }
  .sequence-page i {
    font-size: 17px; }
  .sequence-page span {
    opacity: 0.8; }
  .sequence-page a:hover {
    text-decoration: underline; }

.Toastify button:hover {
  background-color: transparent; }

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.icon-information {
  cursor: pointer; }
  .icon-information .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #4d4d4d;
    color: #fff;
    text-align: left;
    font-size: 14px;
    border-radius: 7px;
    padding: 5px 5px;
    position: absolute;
    /* Position the tooltip */
    z-index: 1; }
  .icon-information span {
    font-family: "Nunito" !important; }

.icon-information:hover .tooltiptext {
  visibility: visible; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #2d71cc; }

input:focus + .slider {
  box-shadow: 0 0 1px #2d71cc; }

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  transform: translateX(13px); }

/* Rounded sliders */
.slider.round {
  border-radius: 16px; }

.slider.round:before {
  border-radius: 50%; }

.botao-destaque {
  background-color: #fff500;
  color: #004a6e;
  font-weight: bold;
  margin-left: 0; }
  .botao-destaque:hover {
    background-color: #d4cf4f; }

.logo-ga {
  max-width: 125px;
  max-height: 60px;
  width: auto; }

.largura-padrao {
  max-width: 1312px;
  margin: 0 auto;
  width: 94%; }

.select-input-option-hiden option:first-child {
  display: none !important; }

.tn-50 {
  max-width: 700px; }

.UsuarioLista {
  margin: auto; }

footer {
  text-align: center;
  padding: 24px 12px;
  margin-top: auto;
  width: 100%;
  bottom: 0; }
  footer p {
    color: #4d4d4d;
    font-size: 1.6em;
    margin: 0; }

#root {
  flex-direction: column;
  position: relative;
  display: flex;
  height: 100%; }

.file-content .label-files {
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  transition: all 0.35s;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 6px 8px;
  display: flex;
  width: 100%; }
  .file-content .label-files label {
    margin: 0; }
    .file-content .label-files label:first-child {
      border-radius: 4px;
      padding: 4px 6px;
      cursor: pointer; }
    .file-content .label-files label:last-child {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 0.5em; }

.dialog-box-input label {
  margin-bottom: 6px; }

.dialog-box-input input {
  height: 47px; }

.file-content input[type="file"] {
  display: none; }

.primary-button-file {
  background-color: #f1f1f1;
  border: 1px solid #d2d2d2; }

@media screen and (max-width: 630px) {
  .nav-menu {
    margin-bottom: 32px; }
  .form-cadastros .form-flex div {
    margin-right: 0;
    width: 100%; }
  .form-cadastros .form-flex {
    flex-wrap: wrap; } }

.rdw-editor-main {
  height: 200px !important;
  background-color: #fdfcfc;
  border-width: 1; }

.warning-corpo-email {
  font-size: 12px;
  color: #ff6b79; }

.card-menu-container {
  background-color: #fff;
  position: absolute;
  flex-direction: column;
  border: solid 1px #e0e0e0;
  align-items: flex-start;
  justify-content: center; }
  .card-menu-container a {
    word-break: keep-all;
    white-space: normal !important;
    width: 100%;
    color: #606060;
    padding: 10px;
    font-size: 14px;
    text-align: left; }
    .card-menu-container a:hover {
      background-color: #eff1f9;
      cursor: pointer; }
    .card-menu-container a i {
      margin-right: 2px;
      font-size: 18px !important; }

.background-login {
  background: url(/static/media/background-login.a927c6e8.svg) center no-repeat;
  background-size: cover;
  height: 100vh; }

@media (max-width: 500px) {
  .form-login {
    width: 100% !important;
    padding: 15px !important; } }

.form-login {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  max-width: 450px;
  padding: 32px;
  width: 90%;
  left: 50%;
  top: 50%; }
  .form-login .logo-ga {
    margin: 0 auto 32px;
    max-width: 170px;
    display: block;
    width: 170px; }
  .form-login label {
    margin-bottom: 0px;
    display: block; }
  .form-login .botao-login {
    background-color: #004473;
    font-family: "Nunito";
    transition: all 0.35s;
    border-radius: 4px;
    padding: 12px 32px;
    margin-left: auto;
    color: #fff;
    font-size: 1.6em;
    cursor: pointer;
    display: block;
    border: none; }
    .form-login .botao-login:hover {
      background-color: #1566a0; }
  .form-login .esqueciSenha {
    cursor: pointer;
    font-size: 13px;
    color: #004473; }
    .form-login .esqueciSenha:hover {
      text-decoration: underline; }

.footer-login {
  text-align: center;
  position: absolute;
  padding: 24px 12px;
  width: 100%;
  bottom: 0; }
  .footer-login p {
    color: #fff;
    font-size: 1.6em;
    margin: 0; }

.pag-obrigado-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .pag-obrigado-container .area-logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0; }
    .pag-obrigado-container .area-logo span {
      color: #606060;
      font-size: 1rem; }
  .pag-obrigado-container .container-body {
    display: flex;
    flex: 1 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto; }
  .pag-obrigado-container .area-texto-obrigado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    max-width: 700px;
    background-color: #ffff;
    border: 1px solid #d8d8d8;
    border-radius: 10px; }
    .pag-obrigado-container .area-texto-obrigado .area-icone {
      padding: 30px;
      border: solid 1px #3f3d56;
      border-radius: 50%; }
    .pag-obrigado-container .area-texto-obrigado h1 {
      font-size: 2rem;
      text-align: center; }
    .pag-obrigado-container .area-texto-obrigado span {
      color: #606060;
      font-size: 1.5rem;
      text-align: center; }
    .pag-obrigado-container .area-texto-obrigado .empresa {
      font-weight: bold; }

@media (max-width: 700px) {
  .area-icone {
    padding: 20px !important; }
  .logo-email-send {
    width: 50px !important; }
  .area-texto-obrigado {
    border: none !important;
    background-color: #f9fbfd !important;
    border-radius: 0px !important; } }

.box-profile {
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.completed-course, .icon{
  margin: auto;
}

.icon {
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
  background-color: #fff;
  justify-content: center;
  transition: all 0.35s;
  border-radius: 4px;
  align-items: center;
  padding: 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-bottom: 32px;
}

.icon:hover {
  box-shadow: 1px 2px 2px #b7b7b71a;
}

.email {
  justify-content: center;
  display: flex;
  font-size: 2em;
}

.completed-course {
  background-color: #fff;
  justify-content: center;
  transition: all 0.35s;
  border-radius: 4px;
  align-items: center;
  padding: 12px;
  width: 100%;
  display: flex;
  font-size: 2em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.completed-course-number {
  background-color: #647fa9;
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.completed-course-name {
  margin-left: 12px;
}

@media (min-width: 550px) {

  .completed-course, .icon{
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
  }

  .icon {
    margin-bottom: 0;
  }
}

.containerCard {
  display: flex;
  flex-direction: column;
  padding: 1px;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
  border: solid 1px #eff1f9;
  background-color: #fff;
  transition: box-shadow 0.35s;
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  overflow: hidden; }
  .containerCard .containerTitle {
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 10px; }
  .containerCard span {
    padding: 2px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #606060;
    font-size: 1.1em; }
  .containerCard .title {
    font-size: 1.5em; }

body {
  background-size: cover; }

#simple-menu .MuiMenu-paper {
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1) !important;
  left: initial !important;
  width: 250px !important;
  right: 9% !important;
  top: 72px !important; }
  #simple-menu .MuiMenu-paper ul {
    display: flex !important;
    flex-direction: column !important; }
    #simple-menu .MuiMenu-paper ul a {
      box-sizing: border-box;
      color: #4d4d4d;
      text-decoration: none;
      align-items: center;
      padding: 10px 16px;
      font-size: 1.6em;
      cursor: pointer;
      display: flex; }
      #simple-menu .MuiMenu-paper ul a i {
        color: #606060;
        margin-right: 4px;
        font-size: 1.2em; }
      #simple-menu .MuiMenu-paper ul a:hover {
        background-color: #f8f8f8;
        color: #606060;
        transition: all 0.35s; }

.background-dashboard {
  background: url(/static/media/background-dashboard.03487bb4.svg) center no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 75vh;
  max-height: 600px; }
  .background-dashboard .container {
    margin: auto; }

.background-principal {
  background: url(/static/media/background-logos.270cfcf9.svg) center no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  height: 100%; }
  .background-principal .container {
    margin: auto; }

.menu-dash {
  background: none; }
  .menu-dash .nav-menu-body #ButtonName {
    color: #fff; }

.menu-options {
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu-options .search {
    margin-right: 10px; }
    .menu-options .search .box {
      position: relative; }
    .menu-options .search .input {
      width: 70px;
      height: 40px;
      background: none;
      box-sizing: border-box;
      outline: none;
      transition: 0.9s; }
    .menu-options .search .box i {
      color: #4d4d4d;
      position: absolute;
      top: 50%;
      right: 15px;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: 26px;
      transition: 0.2s;
      cursor: pointer; }

.container-header {
  min-height: calc(100% - 159px);
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 100%; }

.container-header {
  flex-wrap: wrap-reverse; }
  .container-header .container-sistemas {
    height: 182px; }
  .container-header .container-texto {
    height: auto; }
  .container-header .container-sistemas {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto; }
    .container-header .container-sistemas #video-intro-container {
      position: relative;
      height: 100%;
      z-index: 2; }
      .container-header .container-sistemas #video-intro-container iframe {
        border-radius: 2px;
        box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1); }
      .container-header .container-sistemas #video-intro-container::before {
        content: "";
        border-radius: 2px;
        background-color: transparent;
        border: 1px solid #fff;
        position: absolute;
        height: 100%;
        z-index: -1;
        width: 100%;
        transition: all 0.35s;
        left: -7px;
        top: -7px; }
      .container-header .container-sistemas #video-intro-container:hover::before {
        left: -1px;
        top: -1px; }
      .container-header .container-sistemas #video-intro-container #video-intro {
        height: 100% !important;
        width: 100% !important; }
    .container-header .container-sistemas #img-intro-container {
      position: relative;
      height: 100%;
      z-index: 2; }
      .container-header .container-sistemas #img-intro-container #svg-into {
        height: 100% !important;
        width: 100% !important; }
  .container-header .container-texto {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    width: 100%; }
    .container-header .container-texto h4,
    .container-header .container-texto p {
      text-align: center;
      color: #fff; }
    .container-header .container-texto h4 {
      font-size: 2.4em;
      font-weight: 600;
      margin: 0; }
    .container-header .container-texto p {
      font-size: 2em; }
    .container-header .container-texto button {
      margin: auto; }
    .container-header .container-texto h4,
    .container-header .container-texto p,
    .container-header .container-texto button {
      margin-bottom: 12px;
      margin-top: 12px; }

@media screen and (max-width: 1440px) {
  #simple-menu .MuiMenu-paper {
    right: 4% !important; } }

@media screen and (max-width: 1400px) {
  #simple-menu .MuiMenu-paper {
    right: 3% !important; } }

@media (min-width: 770px) {
  .container-header .container-sistemas,
  .container-header .container-texto {
    height: 250px; }
  .container-header .container-sistemas {
    width: 50%; }
  .container-header .container-texto h4 {
    text-align: left;
    font-size: 2.4em; }
  .container-header .container-texto p {
    text-align: left;
    font-size: 2em; }
  .container-header .container-texto h4,
  .container-header .container-texto p,
  .container-header .container-texto button {
    margin-bottom: 0;
    margin-top: 0; }
  .container-header .container-texto button {
    margin-left: 0; }
  .container-texto {
    width: 50%; } }

@media (min-width: 731px) {
  .container-header .container-sistemas #video-intro-container {
    margin-left: auto;
    max-width: 450px;
    height: 100%;
    z-index: 2; }
    .container-header .container-sistemas #video-intro-container iframe {
      border-radius: 2px;
      box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1); }
  .container-header .container-sistemas #img-intro-container {
    max-width: 500px;
    height: 100%;
    z-index: 2; } }

@media (max-width: 400px) {
  .container-sistemas {
    max-width: 200px; } }

@media (max-width: 960px) {
  .container-sistemas {
    max-width: 300px; } }

@media (min-width: 960px) {
  .container-header .container-sistemas {
    width: 55%; }
    .container-header .container-sistemas #video-intro-container {
      margin-left: auto;
      width: 450px;
      height: 100%;
      z-index: 2; }
      .container-header .container-sistemas #video-intro-container iframe {
        border-radius: 2px;
        box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1); }
    .container-header .container-sistemas #img-intro-container {
      margin-left: auto;
      width: auto;
      height: 100%;
      z-index: 2; }
  .container-header .container-texto {
    display: initial;
    width: 45%; }
    .container-header .container-texto h4 {
      text-align: left;
      font-size: 2.4em; }
    .container-header .container-texto p {
      text-align: left;
      margin: 32px 0 42px;
      font-size: 2em; } }

.MuiDialog-paperWidthSm {
  min-width: 400px !important;
  max-width: 90% !important;
  padding-bottom: 18px;
  position: relative;
  width: auto; }

.header-dialog {
  border-bottom: 1px solid #d2d2d2;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  display: flex;
  width: 100%;
  left: 0;
  top: 0; }
  .header-dialog .header-dialog-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-user-select: none;
            user-select: none;
    font-size: 1.6em;
    overflow: hidden;
    width: 90%; }
  .header-dialog .header-dialog-close {
    transition: color 0.15s;
    color: #4d4d4d;
    position: absolute;
    cursor: pointer;
    display: flex;
    right: 26px; }
    .header-dialog .header-dialog-close:hover {
      color: #d2d2d2; }

.container-dialog-grid {
  grid-template-columns: repeat(4, minmax(0px, auto));
  display: grid; }

.container-dialog {
  padding: 12px 16px;
  width: 100%; }
  .container-dialog .container-dialog-card {
    transition: all 0.35s;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    max-width: 380px;
    cursor: pointer;
    height: 250px;
    padding: 8px;
    width: 100%; }
    .container-dialog .container-dialog-card:hover .container-dialog-body .card-header img {
      -webkit-filter: brightness(20%);
              filter: brightness(20%); }
    .container-dialog .container-dialog-card .container-dialog-body {
      box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
      transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      transition: transform 0.35s, -webkit-transform 0.35s;
      background-color: #fff;
      flex-direction: column;
      border-radius: 4px;
      display: flex;
      width: 100%;
      height: 100%; }
      .container-dialog .container-dialog-card .container-dialog-body .card-header {
        justify-content: space-between;
        border-radius: 4px 4px 0 0;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        display: flex;
        height: 70%; }
        .container-dialog .container-dialog-card .container-dialog-body .card-header img {
          transition: -webkit-filter 0.35s;
          transition: filter 0.35s;
          transition: filter 0.35s, -webkit-filter 0.35s;
          border-radius: 4px 4px 0 0;
          -webkit-filter: brightness(70%);
                  filter: brightness(70%); }
      .container-dialog .container-dialog-card .container-dialog-body .card-footer {
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        align-items: center;
        margin-top: auto;
        display: flex;
        height: 30%; }
        .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-icon {
          border-radius: 0 0 0 4px;
          justify-content: center;
          align-items: center;
          display: flex;
          padding: 12px;
          height: 100%;
          min-width: 20%;
          width: 20%; }
          .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-icon i {
            color: #fff;
            font-size: 3.6em; }
        .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-name {
          justify-content: space-between;
          border-radius: 0 0 4px 0;
          flex-direction: column;
          color: #4d4d4d;
          position: relative;
          display: flex;
          padding: 12px;
          height: 100%;
          min-width: 80%;
          width: 80%; }
          .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-name .icon-ready-module {
            color: #129612;
            font-size: 3.5em;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            top: 35%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            z-index: 1000;
            right: 10px; }
          .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-name p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 80%; }
          .container-dialog .container-dialog-card .container-dialog-body .card-footer .card-footer-name p:first-child {
            font-weight: bold; }

.dialog-load-modulo .MuiDialog-paperScrollPaper {
  background: transparent !important;
  box-shadow: none !important; }

.dialog-load-modulo .container-loading {
  background-color: transparent;
  position: relative; }

@media screen and (max-width: 1440px) {
  .container-dialog {
    grid-template-columns: repeat(4, minmax(0px, auto)); } }

@media screen and (max-width: 1246px) {
  .container-dialog {
    grid-template-columns: repeat(3, minmax(0px, auto)); } }

@media screen and (max-width: 768px) {
  .container-dialog {
    grid-template-columns: repeat(2, minmax(0px, auto)); } }

@media screen and (max-width: 500px) {
  .container-dialog {
    grid-template-columns: repeat(1, minmax(0px, auto)); } }

@media screen and (max-width: 430px) {
  .container-dialog {
    grid-template-columns: repeat(1, minmax(0px, auto)); } }

.canvas-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  z-index: 99;
  width: 100%; }
  .canvas-wrapper .icon-block-module {
    color: #962222;
    font-size: 5em;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    opacity: 0.8; }
  .canvas-wrapper .canvas + .canvas {
    margin-left: 40px; }

.canvas {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none; }

.canvas_copy {
  position: absolute;
  z-index: 100;
  left: 10%;
  top: 15%; }

.canvas_copy--left {
  left: -25%; }

.canvas_copy_title {
  font-size: 1.6em;
  display: block;
  -webkit-transform: translateX(-80px);
          transform: translateX(-80px);
  transition: all 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  padding-right: 20px;
  font-weight: 400;
  color: #fff; }
  .canvas_copy_title span {
    word-break: break-word;
    display: block;
    width: 95%; }
  .canvas_copy_title:nth-child(1) {
    transition-delay: 0.1s; }
  .canvas_copy_title:nth-child(2) {
    transition-delay: 0.2s; }

.canvas_copy_subtitle {
  white-space: nowrap;
  display: block;
  -webkit-transform: rotate(0deg) translateY(-100%) translateX(calc(-100% - 80px));
          transform: rotate(0deg) translateY(-100%) translateX(calc(-100% - 80px));
  -webkit-transform-origin: top left;
          transform-origin: top left;
  position: absolute;
  left: 5px;
  top: 35px;
  font-size: 3em;
  font-weight: 900;
  transition: all 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.25s; }

.canvas_copy_title,
.canvas_copy_subtitle {
  opacity: 0; }

.container-dialog-card:hover .canvas_copy_title,
.container-dialog-card:hover .canvas_copy_subtitle {
  opacity: 1; }

.container-dialog-card:hover .canvas_copy_title {
  -webkit-transform: translateX(10px);
          transform: translateX(10px); }

.container-dialog-card:hover .canvas_copy_subtitle {
  -webkit-transform: rotate(0deg) translateY(-100%) translateX(-105%);
          transform: rotate(0deg) translateY(-100%) translateX(-105%); }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ver-mais-active {
  display: block; }

.ver-mais-invisible {
  display: none; }

.text-ver-mais {
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #524d4d;
  font-size: 1.2em;
  margin: 24px 0 0;
  display: block;
  width: 100%; }

.titulo-cursos {
  color: #4d4d4d;
  border-radius: 2px;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 2.4em;
  font-weight: 100;
  width: 100%; }

.container-curso {
  transition: all 0.65s;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  list-style: none;
  padding: 0 2px; }
  .container-curso li .container-sistemas-card {
    width: 100%; }
    .container-curso li .container-sistemas-card .card-body {
      box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
      border: solid 1px #eff1f9;
      justify-content: space-between;
      background-color: #fff;
      transition: box-shadow 0.35s;
      align-items: flex-start;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      height: 120px;
      display: flex;
      padding: 22px; }
      .container-curso li .container-sistemas-card .card-body .card-body-icon {
        display: flex;
        position: absolute;
        height: 100%;
        top: 0;
        left: -2px;
        align-items: center; }
      .container-curso li .container-sistemas-card .card-body .card-body-after {
        transition: all 0.35s;
        border-radius: 4px;
        position: absolute;
        display: flex;
        width: 2px;
        height: 50%;
        align-items: center;
        text-align: center; }
      .container-curso li .container-sistemas-card .card-body:hover {
        box-shadow: none; }
        .container-curso li .container-sistemas-card .card-body:hover .card-body-after {
          height: 60%; }
      .container-curso li .container-sistemas-card .card-body .card-body-descricao {
        width: calc(100% - 60px); }
        .container-curso li .container-sistemas-card .card-body .card-body-descricao span {
          word-break: break-word;
          color: #4d4d4d;
          font-size: 1.4em;
          display: block; }
        .container-curso li .container-sistemas-card .card-body .card-body-descricao .titulo-curso {
          font-size: 1.8em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .container-curso li .container-sistemas-card .card-body .card-body-descricao .descricao-curso {
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          overflow: hidden;
          height: 60px; }
  .container-curso .cad-semcursos span {
    color: #4d4d4d;
    font-size: 1.5em; }

.container-curso-mais-cursos {
  transition: all 0.65s;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  list-style: none;
  padding: 0 2px; }
  .container-curso-mais-cursos li .container-sistemas-card {
    width: 100%;
    margin-bottom: 10px; }
    .container-curso-mais-cursos li .container-sistemas-card .card-body {
      box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
      border: solid 1px #eff1f9;
      justify-content: space-between;
      background-color: #fff;
      transition: box-shadow 0.35s;
      align-items: flex-start;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      height: 120px;
      display: flex;
      padding: 22px; }
      .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-icon {
        display: flex;
        position: absolute;
        height: 100%;
        top: 0;
        left: -2px;
        align-items: center; }
      .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-value {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px; }
        .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-value span {
          font-size: 1.6em;
          color: #000; }
      .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-after {
        transition: all 0.35s;
        border-radius: 4px;
        position: absolute;
        display: flex;
        width: 2px;
        height: 50%;
        align-items: center;
        text-align: center; }
      .container-curso-mais-cursos li .container-sistemas-card .card-body:hover {
        box-shadow: none; }
        .container-curso-mais-cursos li .container-sistemas-card .card-body:hover .card-body-after {
          height: 60%; }
      .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-descricao {
        width: calc(100% - 60px); }
        .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-descricao span {
          word-break: break-word;
          color: #4d4d4d;
          font-size: 1.4em;
          display: block; }
        .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-descricao .titulo-curso {
          font-size: 1.8em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .container-curso-mais-cursos li .container-sistemas-card .card-body .card-body-descricao .descricao-curso {
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          overflow: hidden;
          height: 60px; }

.card-mais-cursos {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 30px 0px; }

.container-curso-mais-cursos {
  width: 100% !important; }

.container-curso li {
  margin-bottom: 10px; }

@media (min-width: 731px) {
  .ver-mais-active {
    display: none; }
  .ver-mais-invisible {
    display: none; }
  .titulo-cursos {
    width: 230px; }
  .container-curso {
    justify-content: space-between;
    max-height: initial;
    overflow: initial;
    flex-wrap: wrap;
    display: flex;
    padding: 0; }
  .container-curso li {
    display: flex;
    width: calc(50% - 7.5px); }
  .container-curso li:not(:first-child) {
    margin-top: 0; }
  .container-curso li:nth-child(n + 3) {
    margin-top: 15px; } }

@media (min-width: 960px) {
  .container-curso {
    justify-content: flex-start; }
    .container-curso li .container-sistemas-card .card-body {
      padding: 16px; }
  .container-curso li {
    width: calc((100% - 40px) / 3);
    display: flex; }
  .container-curso li:nth-child(n + 3) {
    margin-top: 0; }
  .container-curso li:nth-child(n + 4) {
    margin-top: 20px; }
  .container-curso li:not(:nth-child(3n)) {
    margin-right: 20px; } }

@media (max-width: 700px) {
  .card-body-value {
    left: 0;
    bottom: 0;
    top: revert !important;
    right: revert !important; }
    .card-body-value span {
      font-size: 1.4em !important; } }

@media (min-width: 1200px) {
  .container-curso li {
    width: calc((100% - 60px) / 4); }
  .container-curso li:nth-child(n + 4) {
    margin-top: 0; }
  .container-curso li:nth-child(n + 5) {
    margin-top: 20px; }
  .container-curso li:not(:nth-child(3n)) {
    margin-right: 0; }
  .container-curso li:not(:nth-child(4n)) {
    margin-right: 20px; } }

.titulo-table button {
  margin: 0; }

.table-container {
  border: 1px solid #eff1f9;
  box-shadow: 1px 2px 11px 0 rgba(120, 135, 182, 0.1);
  border-radius: 4px; }

table {
  font-family: "Segoe UI", "Nunito";
  border-collapse: collapse;
  width: 100%; }

thead {
  background-color: #fff;
  color: #fff;
  text-align: left; }

thead tr th,
tbody tr td {
  -webkit-user-select: none;
  color: #606060;
  user-select: none;
  padding: 16px 22px;
  font-weight: 400;
  font-size: 1.4em;
  text-align: left; }
  thead tr th:first-child,
  tbody tr td:first-child {
    text-align: left !important; }
  thead tr th .text-center,
  tbody tr td .text-center {
    text-align: left; }
  thead tr th .flex,
  tbody tr td .flex {
    flex: 1 1; }

thead tr th {
  background-color: #2d71cc;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  font-weight: 600; }
  thead tr th:first-child {
    border-radius: 4px 0 0 0; }
  thead tr th:last-child {
    border-radius: 0 4px 0 0; }

thead tr {
  display: none; }

tbody tr {
  display: flex;
  flex-direction: column;
  transition: all 0.25s; }
  tbody tr:hover {
    background-color: #eff1f9; }
  tbody tr td:before {
    content: attr(data-title);
    text-transform: uppercase;
    color: #606060;
    margin-bottom: 13px;
    line-height: 1.2;
    font-weight: 600;
    min-width: 98px;
    font-size: 1em;
    display: block; }

tbody tr td {
  word-break: break-word; }
  tbody tr td:last-child {
    border-radius: 0 0 4px 4px;
    white-space: nowrap; }

tbody tr {
  border-bottom: 2px solid #f2f2f2; }

tbody tr:last-child {
  border-bottom: none; }

tbody tr {
  background-color: #fff; }

.icon-size {
  transition: all 0.25s;
  font-size: 1.6em;
  cursor: pointer; }
  .icon-size:hover {
    opacity: 0.8; }

.icon-size-md {
  font-size: 2.4em;
  cursor: pointer; }

.icon-green {
  color: #129612; }

.icon-mr-12 {
  margin-right: 12px; }

.edit-table {
  color: #2d71cc; }

.delete-table {
  color: #ff6b79; }

.overflow-text {
  max-width: 100%; }

@media (min-width: 731px) {
  thead tr th,
  tbody tr td {
    padding: 22px 32px; }
    thead tr th:first-child,
    tbody tr td:first-child {
      text-align: center; }
  tbody tr td {
    word-break: normal; } }

@media (max-width: 1600px) {
  .tabela-perguntas {
    overflow-x: auto;
    display: block; } }

@media (max-width: 960px) {
  .tabela-perguntas {
    display: table;
    overflow: initial; } }

@media (min-width: 960px) {
  .tabela-perguntas .overflow-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    display: block; }
  thead tr {
    display: table-row; }
  tbody tr {
    display: table-row;
    flex-direction: column; }
  tbody tr td:before {
    content: initial; } }

.dialog-nota .MuiDialog-paperWidthSm{
   padding-bottom: 0;
}

.notas{
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   padding: 15px;
}

.notas:last-child{
   margin-bottom: 0;
}
.dialog-nota .MuiDialog-paperWidthSm{
   padding-bottom: 0;
}

.notas{
   margin-right: auto;
   margin-left: auto;
   align-items: center;
   padding: 15px;
}

.notas:last-child{
   margin-bottom: 0;
}
.alternativas{
    flex-direction: column;
    display: flex;
}
.teste1:first-child {
  order: 4;
}
.teste2:first-child {
  order: 3;
}
.teste3:first-child {
  order: 2;
}
.teste4:first-child {
  order: 1;
}

.alternativas ul {
  display: flex;
  flex-direction: column;
}

.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.order3 {
  order: 3;
}
.order4 {
  order: 4;
}

@charset "UTF-8";
.container-loading {
  background-color: #fff;
  border-radius: 4px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9; }
  .container-loading .loading {
    color: #4d4d4d;
    align-items: center;
    text-align: center;
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 16px; }

.container-loading-login {
  background-color: rgba(0, 0, 0, 0.2); }

.nome {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: #fafafa;
  background-color: #02022e;
  text-align: center;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  font-size: 30px;
  justify-content: space-between; }

.lista {
  display: flex; }

.myitem {
  color: #fafafa; }

.nav-titulo-video {
  color: #4d4d4d;
  align-items: center;
  font-size: 1.5em;
  display: flex; }
  .nav-titulo-video i {
    font-size: 1.4em; }

.collection {
  z-index: 999;
  transition: width 0.17s;
  width: 300px;
  position: fixed;
  top: 0;
  color: #aeaeae;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #181c1f;
  min-height: 100vh;
  max-height: 100vh;
  list-style-type: none;
  padding: 0 0 0 0.1px;
  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: column; }

.collection .container-logo-ga {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  padding: 24px;
  width: 100%; }
  .collection .container-logo-ga a {
    display: block; }
  .collection .container-logo-ga .container-logo-ga-icon-back {
    margin-right: 20px; }
  .collection .container-logo-ga .container-logo-ga-hamburguer {
    margin-left: auto;
    font-size: 3em;
    cursor: pointer; }

.collection .collection-item i {
  margin-right: 15px;
  color: #aeaeae;
  font-size: 2em; }

.collection .MuiButton-root {
  color: #aeaeae; }

.collection .list-test {
  margin-top: auto; }

.collection .collection-item.myitem {
  background-color: #282c2f; }

.collection .collection-item .MuiList-padding {
  padding-top: 0;
  padding-bottom: 0; }
  .collection .collection-item .MuiList-padding .MuiListItem-button:hover {
    background-color: rgba(14, 14, 14, 0.4); }
  .collection .collection-item .MuiList-padding .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0; }
  .collection .collection-item .MuiList-padding .MuiListItem-gutters {
    padding: 8px 0;
    padding-right: 24px;
    padding-left: 24px; }
    .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root {
      display: block; }
      .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root span {
        transition: width 0.35s;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        width: 100%; }

.collection .list-video-item {
  font-size: 14px; }

.collection li {
  text-transform: capitalize; }

.buttonProva {
  padding: 12px 24px; }
  .buttonProva .MuiButton-text {
    padding: 6px 0; }
    .buttonProva .MuiButton-text:hover {
      background-color: transparent; }
  .buttonProva .MuiButton-label {
    justify-content: flex-start;
    align-items: center;
    display: flex; }
    .buttonProva .MuiButton-label label {
      font-size: 1em;
      cursor: pointer;
      transition: width 0.35s;
      white-space: nowrap;
      overflow: hidden;
      width: 100%; }
    .buttonProva .MuiButton-label .MuiSvgIcon-root {
      margin-right: 12px; }

.icon-question-true {
  position: absolute;
  font-size: 1.4em;
  left: -5px;
  top: 14px; }

.container-video-global {
  width: 100%; }

.container-video-global,
.nav-video,
.footer-video {
  margin-left: 300px;
  transition: left 0.17s; }

.nav-video .nav-menu-body {
  max-width: 1016px; }

.container-video {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 90%; }
  .container-video .react-player {
    width: 100% !important;
    height: 500px !important;
    display: flex; }
    .container-video .react-player .toolbar {
      display: none !important; }

.mobile-pdf {
  text-align: center;
  width: 40%;
  min-width: 250px;
  margin: 0 auto;
  background-color: #004473;
  border-radius: 4px;
  transition: all 0.35s; }
  .mobile-pdf a {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 15px 30px;
    font-size: 1.4em;
    color: #fff;
    font-weight: bold;
    text-decoration: none; }
  .mobile-pdf:hover {
    background-color: #1566a0; }

.container-description {
  max-width: 895.5px;
  width: 80%;
  margin: auto; }
  .container-description .container-description-title {
    align-items: center;
    padding: 32px 0;
    display: flex; }
    .container-description .container-description-title h4 {
      font-weight: bold;
      color: #4d4d4d;
      margin: 0; }
    .container-description .container-description-title hr {
      margin-left: 16px;
      height: 12px;
      width: calc(100% - 185px);
      background-color: #4d4d4d;
      border: none; }
  .container-description button i {
    margin-left: 10px; }
  .container-description .container-description-body {
    padding: 0 0 32px; }
    .container-description .container-description-body p {
      word-break: break-word; }

.video-ready {
  color: #008856 !important; }

.footer-video {
  margin-top: auto;
  width: initial; }

.menu-fechado .collection {
  width: 70px; }
  .menu-fechado .collection .container-logo-ga {
    margin: 0 auto; }
    .menu-fechado .collection .container-logo-ga .logo-ga {
      display: none; }
    .menu-fechado .collection .container-logo-ga .icon-size-md {
      display: none; }
    .menu-fechado .collection .container-logo-ga .container-logo-ga-hamburguer {
      margin: 6px 0px; }
  .menu-fechado .collection .collection-item i {
    margin-right: 12px;
    color: #aeaeae;
    font-size: 2em;
    margin: auto; }
  .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters {
    padding: 8px 0; }
    .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root {
      display: none; }
      .menu-fechado .collection .collection-item .MuiList-padding .MuiListItem-gutters .MuiListItemText-root span {
        transition: width 0.1s;
        white-space: nowrap;
        overflow: hidden;
        width: 0; }

.menu-fechado .buttonProva {
  padding: 12px 0px; }
  .menu-fechado .buttonProva .MuiButton-text {
    padding: 6px 0;
    width: 100%; }
  .menu-fechado .buttonProva .MuiButton-label .MuiSvgIcon-root {
    margin: auto; }
  .menu-fechado .buttonProva .MuiButton-label label {
    transition: width 0.35s;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    font-size: 1em;
    width: 0; }

.menu-fechado .container-video-global,
.menu-fechado .nav-video,
.menu-fechado .footer-video {
  margin-left: 70px; }

.container-dialog-prova {
  padding: 12px 16px 0;
  overflow-y: auto;
  width: 100%; }
  .container-dialog-prova .MuiTypography-displayBlock {
    word-break: break-word;
    white-space: break-spaces; }

.titulo-avaliação,
.titulo-pages {
  font-family: "Segoe UI", "Nunito";
  color: #4d4d4d;
  font-weight: normal;
  margin-bottom: 16px;
  align-items: center;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  display: flex; }
  .titulo-avaliação hr,
  .titulo-pages hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #eff1f9; }
  .titulo-avaliação span,
  .titulo-pages span {
    min-width: -webkit-max-content;
    min-width: max-content;
    font-size: 1.5rem;
    margin-top: 10px;
    color: #004473;
    font-weight: bold; }
  .titulo-avaliação strong,
  .titulo-pages strong {
    color: #004473;
    font-style: oblique; }
  .titulo-avaliação .barra,
  .titulo-pages .barra {
    font-weight: bold;
    margin: 0 16px; }
  .titulo-avaliação.titulo-pages,
  .titulo-pages.titulo-pages {
    padding: 0;
    margin: 10px 0 0 0; }

.enviar-respostas {
  flex-direction: row-reverse;
  justify-content: flex-start;
  display: flex; }

@media (min-width: 450px) {
  .container-video {
    width: 96%; } }

@media (min-width: 691px) {
  .container-video {
    width: 80%;
    max-width: 895.5px; } }

@media (max-width: 1024px) {
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 768px) {
  .container-video .react-player {
    width: 100% !important;
    height: 320px !important; }
  .container-video-global,
  .nav-video,
  .footer-video {
    margin-left: 70px;
    transition: left 0.17s; } }

@media (max-width: 690px) {
  .container-description {
    max-width: 1200px;
    width: 96%;
    margin: auto; } }

@media (max-width: 530px) {
  .container-description {
    width: 90%; }
  .template-video .nav-menu .nav-menu-body {
    flex-direction: column-reverse;
    justify-content: center; }
    .template-video .nav-menu .nav-menu-body .opcoes {
      margin-left: auto; }
  .container-video .react-player {
    width: 100% !important;
    height: 240px !important; } }

