.contentCardContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px;

    p{
        margin-bottom: 10px;
    }
}
