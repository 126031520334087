@import "variaveis.scss";

// Tabela Padrão

.titulo-table {
  button {
    margin: 0;
  }
}

.table-container {
  border: 1px solid $corBordaCurso;
  box-shadow: $boxShadowPadrao;
  border-radius: 4px;
}

table {
  font-family: "Segoe UI", $fontPadrao;
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: $corBranca;
  color: $corBranca;
  text-align: left;
}

thead tr th,
tbody tr td {
  -webkit-user-select: none;
  color: $corTextoPadrao;
  -moz-user-select: none;
  user-select: none;
  padding: 16px 22px;
  font-weight: 400;
  font-size: 1.4em;
  text-align: left;
  &:first-child {
    text-align: left !important;
  }
  .text-center {
    text-align: left;
  }
  .flex {
    flex: 1;
  }
}

thead tr th {
  background-color: $corTituloTabela;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: $corBranca;
  font-weight: 600;
  &:first-child {
    border-radius: 4px 0 0 0;
  }
  &:last-child {
    border-radius: 0 4px 0 0;
  }
}

thead tr {
  display: none;
}

tbody tr {
  display: flex;
  flex-direction: column;
  transition: all 0.25s;
  &:hover {
    background-color: $corBordaCurso;
  }
  td {
    &:before {
      content: attr(data-title);
      text-transform: uppercase;
      color: $corTextoPadrao;
      margin-bottom: 13px;
      line-height: 1.2;
      font-weight: 600;
      min-width: 98px;
      font-size: 1em;
      display: block;
    }
  }
}

tbody tr td {
  &:last-child {
    border-radius: 0 0 4px 4px;
    white-space: nowrap;
  }
  word-break: break-word;
}

tbody tr {
  border-bottom: 2px solid $corBordaTabela;
}

tbody tr:last-child {
  border-bottom: none;
}

tbody tr {
  background-color: $corBranca;
}

.icon-size {
  transition: all 0.25s;
  font-size: 1.6em;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.icon-size-md {
  font-size: 2.4em;
  cursor: pointer;
}

.icon-green {
  color: $iconModColorReady;
}

.icon-mr-12 {
  margin-right: 12px;
}

.edit-table {
  color: $corTituloTabela;
}

.delete-table {
  color: $corDeleteTable;
}

.overflow-text {
  max-width: 100%;
}

// Fim Tabela Padrão

@media (min-width: 731px) {
  thead tr th,
  tbody tr td {
    padding: 22px 32px;
    &:first-child {
      text-align: center;
    }
  }
  tbody tr td {
    word-break: normal;
  }
}

@media (max-width: 1600px) {
  .tabela-perguntas {
    overflow-x: auto;
    display: block;
  }
}

@media (max-width: 960px) {
  .tabela-perguntas {
    display: table;
    overflow: initial;
  }
}

@media (min-width: 960px) {
  .tabela-perguntas {
    .overflow-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
      overflow: hidden;
      display: block;
    }
  }
  thead tr {
    display: table-row;
  }

  tbody tr {
    display: table-row;
    flex-direction: column;
  }
  tbody tr {
    td {
      &:before {
        content: initial;
      }
    }
  }
}
