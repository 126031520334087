.alternativas ul {
  display: flex;
  flex-direction: column;
}

.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.order3 {
  order: 3;
}
.order4 {
  order: 4;
}
