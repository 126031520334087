.pag-obrigado-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .area-logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    span {
      color: #606060;
      font-size: 1rem;
    }
  }

  .container-body {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .area-texto-obrigado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    max-width: 700px;
    background-color: #ffff;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    // margin-bottom: 40px;
    .area-icone {
      padding: 30px;
      border: solid 1px #3f3d56;
      border-radius: 50%;
    }
    h1 {
      font-size: 2rem;
      text-align: center;
    }
    span {
      color: #606060;
      font-size: 1.5rem;
      text-align: center;
    }
    .empresa {
      font-weight: bold;
    }
  }
}

@media (max-width: 700px) {
  .area-icone {
    padding: 20px !important;
  }
  .logo-email-send {
    width: 50px !important;
  }
  .area-texto-obrigado {
    border: none !important;
    background-color: #f9fbfd !important;
    border-radius: 0px !important;
  }
}
