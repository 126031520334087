@import "./variaveis.scss";

.tr-form-group {
    margin-bottom: 1.5rem !important;
}

.tr-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-right: -12px;
    margin-left: -12px;
}

.tr-col-xl,
.tr-col-xl-1,
.tr-col-xl-2,
.tr-col-xl-3,
.tr-col-xl-4,
.tr-col-xl-5,
.tr-col-xl-6,
.tr-col-xl-7,
.tr-col-xl-8,
.tr-col-xl-9,
.tr-col-xl-10,
.tr-col-xl-11,
.tr-col-xl-12,
.tr-col-lg,
.tr-col-lg-1,
.tr-col-lg-2,
.tr-col-lg-3,
.tr-col-lg-4,
.tr-col-lg-5,
.tr-col-lg-6,
.tr-col-lg-7,
.tr-col-lg-8,
.tr-col-lg-9,
.tr-col-lg-10,
.tr-col-lg-11,
.tr-col-lg-12,
.tr-col-md,
.tr-col-md-1,
.tr-col-md-2,
.tr-col-md-3,
.tr-col-md-4,
.tr-col-md-5,
.tr-col-md-6,
.tr-col-md-7,
.tr-col-md-8,
.tr-col-md-9,
.tr-col-md-10,
.tr-col-md-11,
.tr-col-md-12,
.tr-col-sm,
.tr-col-sm-1,
.tr-col-sm-2,
.tr-col-sm-3,
.tr-col-sm-4,
.tr-col-sm-5,
.tr-col-sm-6,
.tr-col-sm-7,
.tr-col-sm-8,
.tr-col-sm-9,
.tr-col-sm-10,
.tr-col-sm-11,
.tr-col-sm-12,
.tr-col,
.tr-col-1,
.tr-col-2,
.tr-col-3,
.tr-col-4,
.tr-col-5,
.tr-col-6,
.tr-col-7,
.tr-col-8,
.tr-col-9,
.tr-col-10,
.tr-col-11,
.tr-col-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 12px;
    padding-left: 12px;
}

.tr-col {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box;
}

.tr-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.tr-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.tr-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.tr-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.tr-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.tr-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.tr-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.tr-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.tr-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.tr-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.tr-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.tr-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media screen and (min-width: 576px) {
    .tr-col-sm {
        flex-basis: 0;
        flex-grow: 1;
        -webkit-box-flex: 1;
        max-width: 100%;
        box-sizing: border-box;
    }

    .tr-col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .tr-col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .tr-col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .tr-col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .tr-col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .tr-col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .tr-col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .tr-col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .tr-col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .tr-col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .tr-col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .tr-col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 768px) {
    .tr-col-md {
        flex-basis: 0;
        flex-grow: 1;
        -webkit-box-flex: 1;
        max-width: 100%;
        box-sizing: border-box;
    }

    .tr-col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .tr-col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .tr-col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .tr-col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .tr-col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .tr-col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .tr-col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .tr-col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .tr-col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .tr-col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .tr-col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .tr-col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 992px) {
    .tr-col-lg {
        flex-basis: 0;
        flex-grow: 1;
        -webkit-box-flex: 1;
        max-width: 100%;
        box-sizing: border-box;
    }

    .tr-col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .tr-col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .tr-col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .tr-col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .tr-col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .tr-col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .tr-col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .tr-col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .tr-col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .tr-col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .tr-col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .tr-col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 1200px) {
    .tr-col-xl {
        flex-basis: 0;
        flex-grow: 1;
        -webkit-box-flex: 1;
        max-width: 100%;
        box-sizing: border-box;
    }

    .tr-col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .tr-col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .tr-col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .tr-col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .tr-col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .tr-col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .tr-col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .tr-col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .tr-col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .tr-col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .tr-col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .tr-col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.tr-m-0 {
    margin: 0 !important;
}

.tr-mt-0 {
    margin-top: 0 !important;
}

.tr-mr-0 {
    margin-right: 0 !important;
}

.tr-mb-0 {
    margin-bottom: 0 !important;
}

.tr-ml-0 {
    margin-left: 0 !important;
}

.tr-m-1 {
    margin: 0.25rem !important;
}

.tr-mt-1 {
    margin-top: 0.25rem !important;
}

.tr-mr-1 {
    margin-right: 0.25rem !important;
}

.tr-mb-1 {
    margin-bottom: 0.25rem !important;
}

.tr-ml-1 {
    margin-left: 0.25rem !important;
}

.tr-m-2 {
    margin: 0.5rem !important;
}

.tr-mt-2 {
    margin-top: 0.5rem !important;
}

.tr-mr-2 {
    margin-right: 0.5rem !important;
}

.tr-mb-2 {
    margin-bottom: 0.5rem !important;
}

.tr-ml-2 {
    margin-left: 0.5rem !important;
}

.tr-m-3 {
    margin: 1rem !important;
}

.tr-mt-3 {
    margin-top: 1rem !important;
}

.tr-mr-3 {
    margin-right: 1rem !important;
}

.tr-mb-3 {
    margin-bottom: 1rem !important;
}

.tr-ml-3 {
    margin-left: 1rem !important;
}

.tr-m-4 {
    margin: 1.5rem !important;
}

.tr-mt-4 {
    margin-top: 1.5rem !important;
}

.tr-mr-4,
.tr-mx-4 {
    margin-right: 1.5rem !important;
}

.tr-mb-4 {
    margin-bottom: 1.5rem !important;
}

.tr-ml-4 {
    margin-left: 1.5rem !important;
}

.tr-m-5 {
    margin: 3rem !important;
}

.tr-mt-5 {
    margin-top: 3rem !important;
}

.tr-mr-5 {
    margin-right: 3rem !important;
}

.tr-mb-5 {
    margin-bottom: 3rem !important;
}

.tr-ml-5 {
    margin-left: 3rem !important;
}

.tr-dialog-sm,
.tr-dialog-md,
.tr-dialog-lg {
    width: 100%;
}

.tr-display-flex {
    display: flex !important;
}

.tr-space-between {
    justify-content: space-between !important;
}

.tr-flex-end {
    justify-content: flex-end !important;
}

.tr-align-itens {
    align-items: center !important;
}

@media (min-width: 768px) {
    .tr-dialog-sm {
        width: 400px;
    }
    .tr-dialog-md {
        width: 600px;
    }
    .tr-dialog-lg {
        width: 800px;
    }
}
