@import "../../components/css/variaveis.scss";

.background-login {
  background: url(../../components/img/background-login.svg) center no-repeat;
  background-size: cover;
  height: 100vh;
}

@media (max-width: 500px) {
  .form-login {
    width: 100% !important;
    padding: 15px !important;
  }
}

.form-login {
  transform: translate(-50%, -50%);
  background-color: $corBranca;
  border-radius: 4px;
  position: absolute;
  max-width: 450px;
  padding: 32px;
  width: 90%;
  left: 50%;
  top: 50%;

  .logo-ga {
    margin: 0 auto 32px;
    max-width: 170px;
    display: block;
    width: 170px;
  }

  label {
    margin-bottom: 0px;
    display: block;
  }

  .botao-login {
    background-color: $botaoPrimario;
    font-family: $fontPadrao;
    transition: all 0.35s;
    border-radius: 4px;
    padding: 12px 32px;
    margin-left: auto;
    color: $corBranca;
    font-size: 1.6em;
    cursor: pointer;
    display: block;
    border: none;
    &:hover {
      background-color: $botaoPrimarioHover;
    }
  }
  .esqueciSenha {
    cursor: pointer;
    font-size: 13px;
    color: $botaoPrimario;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-login {
  text-align: center;
  position: absolute;
  padding: 24px 12px;
  width: 100%;
  bottom: 0;
  p {
    color: $corBranca;
    font-size: 1.6em;
    margin: 0;
  }
}
