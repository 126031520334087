@import "../variaveis.scss";

.MuiDialog-paperWidthSm {
  min-width: 400px !important;
  max-width: 90% !important;
  padding-bottom: 18px;
  position: relative;
  width: auto;
}

.header-dialog {
  border-bottom: 1px solid $corBordaInput;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  .header-dialog-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    font-size: 1.6em;
    overflow: hidden;
    width: 90%;
  }
  .header-dialog-close {
    transition: color 0.15s;
    color: $corMenuPadrao;
    position: absolute;
    cursor: pointer;
    display: flex;
    right: 26px;
    &:hover {
      color: $corBordaInput;
    }
  }
}

.container-dialog-grid {
  grid-template-columns: repeat(4, minmax(0px, auto));
  display: grid;
}

.container-dialog {
  padding: 12px 16px;
  // overflow-y: auto;
  width: 100%;
  .container-dialog-card {
    transition: all 0.35s;
    vertical-align: middle;
    user-select: none;
    max-width: 380px;
    cursor: pointer;
    height: 250px;
    padding: 8px;
    width: 100%;
    &:hover {
      .container-dialog-body {
        .card-header {
          img {
            filter: brightness(20%);
          }
        }
      }
    }
    .container-dialog-body {
      box-shadow: $boxShadowPadrao;
      transition: transform 0.35s;
      background-color: $corBranca;
      flex-direction: column;
      border-radius: 4px;
      display: flex;
      width: 100%;
      height: 100%;
      .card-header {
        justify-content: space-between;
        border-radius: 4px 4px 0 0;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        display: flex;
        height: 70%;
        img {
          transition: filter 0.35s;
          border-radius: 4px 4px 0 0;
          filter: brightness(70%);
        }
      }
      .card-footer {
        background-color: $corBranca;
        border-radius: 0 0 4px 4px;
        align-items: center;
        margin-top: auto;
        display: flex;
        height: 30%;
        .card-footer-icon {
          border-radius: 0 0 0 4px;
          justify-content: center;
          align-items: center;
          display: flex;
          padding: 12px;
          height: 100%;
          min-width: 20%;
          width: 20%;
          i {
            color: $corBranca;
            font-size: 3.6em;
          }
        }
        .card-footer-name {
          justify-content: space-between;
          border-radius: 0 0 4px 0;
          flex-direction: column;
          color: $corMenuPadrao;
          position: relative;
          display: flex;
          padding: 12px;
          height: 100%;
          min-width: 80%;
          width: 80%;
          .icon-ready-module {
            color: $iconModColorReady;
            font-size: 3.5em;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            top: 35%;
            transform: translateY(-50%);
            z-index: 1000;
            right: 10px;
          }
          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 80%;
          }
          p:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.dialog-load-modulo {
  .MuiDialog-paperScrollPaper {
    background: transparent !important;
    box-shadow: none !important;
    // overflow: scroll;
  }
  .container-loading {
    background-color: transparent;
    position: relative;
  }
}

// Resoluções Menores

// Resolução 1440px

@media screen and (max-width: 1440px) {
  .container-dialog {
    grid-template-columns: repeat(4, minmax(0px, auto));
  }
}

// Resolução 1246px

@media screen and (max-width: 1246px) {
  .container-dialog {
    grid-template-columns: repeat(3, minmax(0px, auto));
  }
}

// Resolução 768px

@media screen and (max-width: 768px) {
  .container-dialog {
    grid-template-columns: repeat(2, minmax(0px, auto));
  }
}

// Resolução 500px

@media screen and (max-width: 500px) {
  .container-dialog {
    grid-template-columns: repeat(1, minmax(0px, auto));
  }
}

// Resolução 430px

@media screen and (max-width: 430px) {
  .container-dialog {
    grid-template-columns: repeat(1, minmax(0px, auto));
  }
}

// Canvas Animação Card's

$transition-ease-in-out: all 0.25s ease-in-out 0s;
$transition-cubic-bezier: all 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
$animation-offset: 80px;

.canvas-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  z-index: 99;
  width: 100%;

  .icon-block-module {
    color: $iconModColorBlock;
    font-size: 5em;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $iconModColor;
    opacity: 0.8;
  }

  .canvas + .canvas {
    margin-left: 40px;
  }
}
.canvas {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
}
.canvas_copy {
  position: absolute;
  z-index: 100;
  left: 10%;
  top: 15%;
}
.canvas_copy--left {
  left: -25%;
}
.canvas_copy_title {
  font-size: 1.6em;
  display: block;
  transform: translateX(-#{$animation-offset});
  transition: $transition-cubic-bezier 0s;
  padding-right: 20px;
  font-weight: 400;
  color: $corBranca;
  span {
    word-break: break-word;
    display: block;
    width: 95%;
  }
  &:nth-child(1) {
    transition-delay: 0.1s;
  }
  &:nth-child(2) {
    transition-delay: 0.2s;
  }
}
.canvas_copy_subtitle {
  white-space: nowrap;
  display: block;
  transform: rotate(0deg) translateY(-100%)
    translateX(calc(-100% - #{$animation-offset}));
  transform-origin: top left;
  position: absolute;
  left: 5px;
  top: 35px;
  font-size: 3em;
  font-weight: 900;
  transition: all 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.25s;
}

.canvas_copy_title,
.canvas_copy_subtitle {
  opacity: 0;
}
.container-dialog-card:hover {
  .canvas_copy_title,
  .canvas_copy_subtitle {
    opacity: 1;
  }
  .canvas_copy_title {
    transform: translateX(10px);
  }
  .canvas_copy_subtitle {
    transform: rotate(0deg) translateY(-100%) translateX(-105%);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
